define("ods-app/templates/cliente/record/perfiles-puestos/record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SSzjj+s9",
    "block": "{\"symbols\":[\"modal\",\"footer\",\"nav\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHidden\",\"size\",\"open\"],[[26,\"action\",[[21,0,[]],\"discardChanges\"],null],\"lg\",true]],{\"statements\":[[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Perfil de puesto\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n\"],[4,\"bs-nav\",null,[[\"type\"],[\"tabs\"]],{\"statements\":[[4,\"component\",[[21,3,[\"item\"]]],null,{\"statements\":[[4,\"component\",[[21,3,[\"link-to\"]],\"cliente.record.perfiles-puestos.record.descriptor\"],null,{\"statements\":[[0,\"              Descriptor\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,3,[\"item\"]]],null,{\"statements\":[[4,\"component\",[[21,3,[\"link-to\"]],\"cliente.record.perfiles-puestos.record.perfil\"],null,{\"statements\":[[0,\"              Perfil\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[3]},null],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"tab-content\"],[8],[0,\"\\n          \"],[1,[20,\"outlet\"],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"forms/controls/ace-async-save-button\",null,[[\"onClick\"],[[26,\"action\",[[21,0,[]],\"submit\"],null]]]],false],[0,\"\\n\\n\"],[4,\"bs-button\",null,[[\"onClick\",\"size\"],[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],\"xs\"]],{\"statements\":[[0,\"      Cancelar\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/cliente/record/perfiles-puestos/record.hbs"
    }
  });

  _exports.default = _default;
});