define("ods-app/routes/requerimiento/record/informacion-compensacion", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute", "ods-app/mixins/permission-validator"], function (_exports, _isTabSubroute, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, _permissionValidator.default, {
    routePermission: 'VIEW_RQ_TAB_COMPENSATION',
    noPermissionTransitionRoute: 'requerimiento',

    model() {
      let parentData = this._super(...arguments);

      if (parentData.record.get('isNew')) {
        return Ember.merge(parentData, {
          planillaCargos: Ember.A([])
        });
      }

      return this.store.query('requerimiento-planilla-cargo', {
        filter: {
          requerimiento: parentData.record.get('id')
        },
        include: 'planillaCargo'
      }).then(planillaCargos => Ember.merge(parentData, {
        planillaCargos: planillaCargos.toArray()
      }));
    }

  });

  _exports.default = _default;
});