define("ods-app/templates/usuario/record/acceso", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VisXrvnB",
    "block": "{\"symbols\":[\"f\",\"role\"],\"statements\":[[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n\"],[4,\"component\",[[21,1,[\"ace-power-select\"]],\"role\",\"Rol:\"],[[\"selected\",\"options\",\"searchField\",\"placeholder\",\"onchange\"],[[22,[\"changeset\",\"role\"]],[22,[\"roles\"]],\"nombre\",\"Escribe aquí...\",[26,\"action\",[[21,0,[]],[26,\"mut\",[[22,[\"changeset\",\"role\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[21,2,[\"nombre\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"username\",\"Usuario\"],[[\"placeholder\"],[\"Usuario\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-password-field\"]],\"plainPassword\",\"Contraseña\"],[[\"placeholder\",\"update\"],[\"Contraseña\",[26,\"action\",[[21,0,[]],\"updatePassword\"],null]]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-password-field\"]],\"confirmPassword\",\"Confirmar contraseña\"],[[\"placeholder\",\"update\"],[\"Contraseña\",[26,\"action\",[[21,0,[]],\"updatePassword\"],null]]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"passwordResetted\",\"Contraseña reiniciada\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/usuario/record/acceso.hbs"
    }
  });

  _exports.default = _default;
});