define("ods-app/routes/aplicacion/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ember-changeset-validations/validators", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/is-codigo"], function (_exports, _abstractModuleRecord, _hasTabs, _validators, _customPresence, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    modelName: 'aplicacion',
    indexRoute: 'aplicacion',
    defaultTabSubRoute: 'informacion',
    breadcrumbsRoute: Ember.computed('recordId', function () {
      return [{
        name: 'Aplicaciones',
        route: 'aplicacion'
      }, {
        name: 'Editar',
        route: `aplicacion.record.${this.defaultTabSubRoute}`,
        queryParam: this.recordId
      }];
    }),
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        candidato: (0, _validators.validatePresence)({
          presence: true
        }),
        requerimiento: (0, _validators.validatePresence)({
          presence: true
        }),
        createdAt: (0, _validators.validatePresence)({
          presence: true
        }),
        expectativaSalarial: [(0, _validators.validatePresence)({
          presence: true
        }), (0, _validators.validateNumber)()],
        fuenteReclutamiento: (0, _validators.validatePresence)({
          presence: true
        }),
        estatus: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [{
        name: 'fuentesReclutamiento',
        modelName: 'fuente-reclutamiento',
        queryParams: {
          filter: {
            tipo: this.constants.REQUIREMENT_SOURCE_TYPE_CANDIDATO
          }
        }
      }, {
        name: 'requerimientos',
        modelName: 'requerimiento'
      }, {
        name: 'candidatos',
        modelName: 'candidato',
        queryParams: {
          filter: {
            listaNegra: 0
          }
        }
      }];

      if (!this.get('isNew') && this.get('recordId')) {
        relatedModels.push({
          name: 'llamadas',
          modelName: 'llamada',
          queryParams: {
            filter: {
              aplicacion: this.get('recordId')
            }
          }
        }, {
          name: 'fuentesReclutamiento',
          modelName: 'fuente-reclutamiento',
          queryParams: {
            filter: {
              tipo: this.constants.REQUIREMENT_SOURCE_TYPE_CANDIDATO,
              activo: 1
            }
          }
        }, {
          name: 'entrevistas',
          modelName: 'entrevista',
          queryParams: {
            filter: {
              aplicacion: this.get('recordId')
            }
          }
        });
      }

      return relatedModels;
    })
  });

  _exports.default = _default;
});