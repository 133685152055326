define("ods-app/routes/notification-config/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo"], function (_exports, _abstractModuleRecord, _hasTabs, _validators, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    modelName: 'notification-config',
    indexRoute: 'notification-config',
    defaultTabSubRoute: 'configuracion',
    breadcrumbsRoute: Ember.computed('recordId', function () {
      return [{
        name: 'Notificaciones dinámicas',
        route: 'notification-config'
      }, {
        name: 'Editar',
        route: `notification-config.record.${this.defaultTabSubRoute}`,
        queryParam: this.recordId
      }];
    }),
    relatedModelsToQuery: Ember.computed(function () {
      return [{
        name: 'roles',
        modelName: 'role',
        queryParams: {
          filter: {
            activo: true
          }
        }
      }];
    }),
    validations: Ember.computed(function () {
      return {
        code: [(0, _validators.validatePresence)({
          presence: true
        }), (0, _isCodigo.default)()],
        name: (0, _validators.validatePresence)({
          presence: true
        }),
        subjectTemplate: (0, _validators.validatePresence)({
          presence: true
        }),
        bodyTemplate: (0, _validators.validatePresence)({
          presence: true
        }),
        event: (0, _validators.validatePresence)({
          presence: true
        })
      };
    })
  });

  _exports.default = _default;
});