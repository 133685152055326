define("ods-app/templates/pais/record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gzzf0gE+",
    "block": "{\"symbols\":[\"modal\",\"footer\",\"f\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHidden\"],[[26,\"action\",[[21,0,[]],\"discardChanges\"],null]]],{\"statements\":[[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"model\",\"record\",\"isNew\"]]],null,{\"statements\":[[0,\"        Crear país\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Editar país\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"      \"],[1,[26,\"component\",[[21,3,[\"ace-text-field\"]],\"codigo\",\"Código:\"],[[\"placeholder\"],[\"Código\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,3,[\"ace-text-field\"]],\"nombre\",\"Nombre:\"],[[\"placeholder\"],[\"Nombre\"]]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"forms/controls/ace-async-save-button\",null,[[\"onClick\"],[[26,\"action\",[[21,0,[]],\"submit\"],null]]]],false],[0,\"\\n\\n\"],[4,\"bs-button\",null,[[\"onClick\",\"size\"],[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],\"xs\"]],{\"statements\":[[0,\"      Cancelar\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/pais/record.hbs"
    }
  });

  _exports.default = _default;
});