define("ods-app/templates/proyecto/record/requerimientos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qfKAAapK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[26,\"not\",[[26,\"or\",[[26,\"eq\",[[22,[\"changeset\",\"estatus\"]],[22,[\"constants\",\"PROJECT_STATUS_CANCELED\"]]],null],[26,\"eq\",[[22,[\"changeset\",\"estatus\"]],[22,[\"constants\",\"PROJECT_STATUS_COMPLETED\"]]],null]],null]],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"pull-right padding-bottom-15\"],[8],[0,\"\\n    \"],[6,\"a\"],[10,\"href\",\"#\"],[10,\"class\",\"btn btn-success btn-xs\"],[3,\"action\",[[21,0,[]],\"arrancarReclutamiento\"]],[8],[0,\"\\n      \"],[6,\"i\"],[10,\"class\",\"fa fa-plus-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n      Nuevo requerimiento de personal\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"modelSortingProperties\",\"includedRelationships\",\"load\"],[[22,[\"columns\"]],[22,[\"modelName\"]],[22,[\"modelClass\"]],[22,[\"modelFilters\"]],[22,[\"modelSortingProperties\"]],[22,[\"includedRelationships\"]],[22,[\"reload\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/proyecto/record/requerimientos.hbs"
    }
  });

  _exports.default = _default;
});