define("ods-app/controllers/cliente/record/perfiles-puestos", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/cliente-perfil-puesto"], function (_exports, _abstractModuleIndex, _clientePerfilPuesto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    editRoute: 'cliente.record.perfiles-puestos.record',
    modelClass: _clientePerfilPuesto.default,
    reload: true,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'cliente'
        },
        value: this.get('record.id') || -1
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Departamento',
        valuePath: 'nombreDepartamento',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'tipo'
      }, {
        label: 'Puesto',
        valuePath: 'nombrePuesto',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'nombre'
      }, {
        label: 'Jefe inmediato administrativo',
        valuePath: 'jefeInmediatoAdministrativo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),

    reloadModel() {
      this.set('reload', true);
    }

  });

  _exports.default = _default;
});