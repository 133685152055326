define("ods-app/routes/requerimiento/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ods-app/mixins/ods-app-file-saver", "ods-app/mixins/permission-validator", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/is-codigo", "ember-changeset-validations/validators", "ods-app/validators/requerimiento-tipo-plaza-facturacion", "ods-app/validators/requerimiento-tipo-plaza-planilla", "moment", "ods-app/utils/common", "ods-app/utils/app-utils"], function (_exports, _abstractModuleRecord, _hasTabs, _odsAppFileSaver, _permissionValidator, _customPresence, _isCodigo, _validators, _requerimientoTipoPlazaFacturacion, _requerimientoTipoPlazaPlanilla, _moment, _common, _appUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    isTruthhy
  } = _appUtils.default;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, _odsAppFileSaver.default, _permissionValidator.default, {
    modelName: 'requerimiento',
    indexRoute: 'requerimiento',
    defaultTabSubRoute: 'informacion',
    routePermission: 'VIEW_REQUIREMENTS',
    user: Ember.computed.alias('currentUser.user'),
    proyectoPlaceHolderId: null,
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    datesCalculator: Ember.inject.service(),
    ajaxService: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    breadcrumbsRoute: Ember.computed('recordId', function () {
      let record = this.controller.get('record');
      let indexRoute = {
        name: 'Requerimientos',
        route: 'requerimiento'
      };

      if ([this.constants.REQUIREMENT_STATUS_CONTRATADO, this.constants.REQUIREMENT_STATUS_REPROCESO].includes(record.get('estatus'))) {
        indexRoute = {
          name: 'Requerimiento histórico',
          route: 'requerimiento-historico'
        };
      }

      return [indexRoute, {
        name: 'Editar',
        route: `requerimiento.record.${this.defaultTabSubRoute}`,
        queryParam: this.recordId
      }];
    }),
    validations: Ember.computed(function () {
      const configs = this.get('currentUser.configs');
      let validaciones = {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        proyecto: (0, _customPresence.default)({
          presence: true
        }),
        fechaSolicitud: (0, _customPresence.default)({
          presence: true
        }),
        estatus: (0, _customPresence.default)({
          presence: true
        }),
        reclutador: (0, _customPresence.default)({
          presence: true
        }),
        plaza: (0, _customPresence.default)({
          presence: true
        }),
        tipoPlaza: (0, _customPresence.default)({
          presence: true
        }),
        nombrePlaza: (0, _customPresence.default)({
          presence: true
        }),
        nombreJefeInmediato: (0, _customPresence.default)({
          presence: true
        }),
        jornada: (0, _customPresence.default)({
          presence: true
        }),
        cantidadPlazas: [(0, _customPresence.default)({
          presence: true
        }), (0, _validators.validateNumber)({
          gte: 0
        })],
        fechaSeguimientoProgramada: (0, _customPresence.default)({
          presence: true
        }),
        anosExperiencia: (0, _validators.validateNumber)({
          gte: 0
        }),
        personalBajoCargo: (0, _validators.validateNumber)({
          gte: 0
        }),
        bonificacionIncenAdmin: (0, _validators.validateNumber)({
          gte: 0
        }),
        bonificacionIncen: (0, _validators.validateNumber)({
          gte: 0
        }),
        bonoTransporte: (0, _validators.validateNumber)({
          gte: 0
        }),
        comisiones: (0, _validators.validateNumber)({
          gte: 0
        }),
        comisionesEspecificas: (0, _validators.validateNumber)({
          gte: 0
        }),
        gasolina: (0, _validators.validateNumber)({
          gte: 0
        })
      };
      let configPais = configs.findBy('codigo', 'PAIS_OBLIGATORIO_REQUERIMIENTO');
      let configDepartamento = configs.findBy('codigo', 'DEPARTAMENTO_OBLIGATORIO_REQUERIMIENTO');
      let configMunicipio = configs.findBy('codigo', 'MUNICIPIO_OBLIGATORIO_REQUERIMIENTO');
      let configEdad = configs.findBy('codigo', 'EDAD_REQUERIMIENTO');
      let configGenero = configs.findBy('codigo', 'GENERO_REQUERIMIENTO');
      let configEstadoCivil = configs.findBy('codigo', 'ESTADO_CIVIL_REQUERIMIENTO');
      let configPresentacion = configs.findBy('codigo', 'PRESENTACION_REQUERIMIENTO');

      if (configPais) {
        if (configPais.get('activo') && isTruthhy(configPais.get('valor'))) {
          let validacionPais = {
            pais: (0, _customPresence.default)({
              presence: true
            })
          };
          validaciones = Object.assign(validaciones, validacionPais);
        }
      }

      if (configDepartamento) {
        if (configDepartamento.get('activo') && isTruthhy(configDepartamento.get('valor'))) {
          let validacionDepartamento = {
            departamento: (0, _customPresence.default)({
              presence: true
            })
          };
          validaciones = Object.assign(validaciones, validacionDepartamento);
        }
      }

      if (configMunicipio) {
        if (configMunicipio.get('activo') && isTruthhy(configMunicipio.get('valor'))) {
          let validacionMunicipio = {
            municipio: (0, _customPresence.default)({
              presence: true
            })
          };
          validaciones = Object.assign(validaciones, validacionMunicipio);
        }
      }

      if (configEdad) {
        if (configEdad.get('activo') && isTruthhy(configEdad.get('valor'))) {
          let validacionEdad = {
            edad: (0, _customPresence.default)({
              presence: true
            })
          };
          validaciones = Object.assign(validaciones, validacionEdad);
        }
      }

      if (configGenero) {
        if (configGenero.get('activo') && isTruthhy(configGenero.get('valor'))) {
          let validacionGenero = {
            genero: (0, _customPresence.default)({
              presence: true
            })
          };
          validaciones = Object.assign(validaciones, validacionGenero);
        }
      }

      if (configEstadoCivil) {
        if (configEstadoCivil.get('activo') && isTruthhy(configEstadoCivil.get('valor'))) {
          let validacionEstadoCivil = {
            estadoCivil: (0, _customPresence.default)({
              presence: true
            })
          };
          validaciones = Object.assign(validaciones, validacionEstadoCivil);
        }
      }

      if (configPresentacion) {
        if (configPresentacion.get('activo') && isTruthhy(configPresentacion.get('valor'))) {
          let validacionPresentacion = {
            presentacion: (0, _validators.validateNumber)({
              gte: 1,
              lte: 10
            })
          };
          validaciones = Object.assign(validaciones, validacionPresentacion);
        }
      }

      return validaciones;
    }),
    relatedModelsToQuery: Ember.computed('isNew', 'proyectoPlaceHolderId', function () {
      let relatedModels = [{
        name: 'paises',
        modelName: 'pais'
      }, {
        name: 'departamentos',
        modelName: 'departamento'
      }, {
        name: 'municipios',
        modelName: 'municipio'
      }, {
        name: 'usuarios',
        modelName: 'usuario',
        queryParams: {
          filter: {
            'role.puedeSerReclutador': 1
          },
          include: 'role',
          sort: 'id'
        }
      }];

      if (!this.get('isNew') && this.get('recordId')) {
        relatedModels.push({
          name: 'ternas',
          modelName: 'terna',
          queryParams: {
            filter: {
              requerimiento: this.get('recordId')
            }
          }
        }, // Necesitamos lo cargos para hacer una validación al "Iniciar proceso".
        {
          name: 'planillaCargos',
          modelName: 'requerimiento-planilla-cargo',
          queryParams: {
            filter: {
              requerimiento: this.get('recordId')
            },
            include: 'planillaCargo'
          }
        });
      }

      if (this.get('proyectoPlaceHolderId')) {
        relatedModels.push({
          name: 'proyectosPlaceHolder',
          modelName: 'proyecto',
          queryParams: {
            filter: {
              id: this.get('proyectoPlaceHolderId')
            },
            include: 'servicioAdquirido,clienteContacto'
          }
        });
      }

      return relatedModels;
    }),

    model() {
      // Después del super, cargar la data necesaria para mostrar correctamente
      // la información de proyecto.
      return this._super(...arguments).then(modelData => {
        let record = modelData.record;

        if (record.get('isNew')) {
          return modelData;
        }

        let proyecto = record.get('proyecto');
        let promisesArray = [proyecto];
        return Ember.RSVP.all(promisesArray).then(() => {
          promisesArray = [proyecto.get('cliente'), proyecto.get('servicioAdquirido')];
          return Ember.RSVP.all(promisesArray);
        }).then(() => Ember.RSVP.hash({
          proyectoCandidatos: this.store.query('proyecto-candidato', {
            filter: {
              'proyecto': proyecto.get('id')
            }
          })
        })).then(data => Ember.merge(modelData, data));
      });
    },

    createRecordInstance(params, transition) {
      let modelName = this.get('modelName'); // Por defecto this.fechaSolicitud viene en null, por lo que el moment construye
      // la fecha del día

      let fechaSolicitud = (0, _moment.default)(this.fechaSolicitud); // Si la hora a la que se está creando el requerimiento es mayor a las 11:00 a.m.
      // entonces registrar como fecha de solicitud el día siguiente

      if (parseInt(fechaSolicitud.format('HH')) > 10) {
        fechaSolicitud = this.get('datesCalculator').getFechaLuegoDeDiasHabiles(fechaSolicitud, 1);
      } // La fecha de seguimiento programada se establece como el tercer día hábil
      // posterior a la fecha registrada de solicitud


      let fechaSeguimientoProgramada = this.get('datesCalculator').getFechaLuegoDeDiasHabiles(fechaSolicitud, 3); // Si la URL trae un queryParam proyectoId entonces el requerimiento que estamos
      // instanciando debe estar asociado a ese proyecto, por lo que lo seteamos
      // en el placeholder de esta ruta para que el model hook lo vaya a buscar

      if (!Ember.isNone(transition.queryParams.proyectoId)) {
        this.set('proyectoPlaceHolderId', transition.queryParams.proyectoId);
      }

      return this.store.createRecord(modelName, {
        estatus: this.constants.REQUIREMENT_STATUS_BORRADOR,
        fechaSeguimientoProgramada: fechaSeguimientoProgramada.toDate(),
        anosExperiencia: 0,
        personalBajoCargo: 0,
        cantidadPlazas: 1,
        fechaSolicitud: fechaSolicitud.toDate()
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      let user = this.get('user');

      if (user.get('role.codigo') != 'OP01') {
        this.validations['salarioBase'] = (0, _requerimientoTipoPlazaPlanilla.default)({
          changeset: controller.changeset
        });
        this.validations['bonificacionDecreto'] = (0, _requerimientoTipoPlazaPlanilla.default)({
          changeset: controller.changeset
        });
        this.validations['montoHonorario'] = (0, _requerimientoTipoPlazaFacturacion.default)({
          changeset: controller.changeset
        });
      }

      if (model.record.get('isNew')) {
        let requerimiento = model.record;
        let user = controller.get('usuarios').findBy('role.codigo', this.constants.ROLE_GERENCIA_RH_CODE);
        requerimiento.set('reclutador', user);
      }

      if (!Ember.isNone(controller.get('proyectosPlaceHolder'))) {
        let requerimiento = model.record;
        let proyecto = controller.get('proyectosPlaceHolder.firstObject');
        let clienteContacto = proyecto.get('clienteContacto');
        requerimiento.set('proyecto', proyecto);
        requerimiento.set('responsableSolicitud', clienteContacto.get('nombre'));
        requerimiento.set('responsablePuesto', clienteContacto.get('puesto'));
        requerimiento.set('responsableCorreo', clienteContacto.get('correo'));
        requerimiento.set('responsableTelefono', clienteContacto.get('telefono'));

        if (proyecto.get('servicioAdquirido.tipoServicio') === this.constants.SERVICE_TYPES_PAYROLL_ADMINISTRATION_FACTORH) {
          requerimiento.set('tipoPlaza', this.constants.HIRING_TYPES_PLANILLA);
        }
      }
    },

    actions: {
      iniciarProcesoDeReclutamiento() {
        let changeset = this.controller.changeset;
        let tipoServicio = changeset.get('proyecto').get('servicioAdquirido.tipoServicio'); // Validar que ya se haya asginado un puesto de FactoRH.

        if (tipoServicio === this.constants.SERVICE_TYPES_PAYROLL_ADMINISTRATION_FACTORH && changeset.get('tipoPlaza') === this.constants.HIRING_TYPES_PLANILLA) {
          if (!changeset.get('factorhPuestoId')) {
            this.toast.error('Debe asignar un puesto de FactoRH al requerimiento.');
            return;
          }

          let reqCargosSinTipoDecuento = changeset.get('planillaCargos').filterBy('generaDescuentoEmpleado').rejectBy('planillaCargo.factorhTipoDescuentoId').mapBy('planillaCargo.nombre');

          if (reqCargosSinTipoDecuento.length) {
            this.toast.error(`Los siguientes cargos que generan descuento de empleado
            no tienen configurado su tipo de descuento de FactoRH: ${reqCargosSinTipoDecuento.join(', ')}
            `);
            return;
          }
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de iniciar el proceso de reclutamiento?`,
          message: ['Ya no podrá realizar ningún cambio sobre los datos generales, el perfil', 'laboral y la información de compensación.'].join(' '),
          onSubmit: () => {
            // Por defecto this.fechaSolicitud viene en null, por lo que el moment construye
            // la fecha del día
            let fechaSolicitud = (0, _moment.default)(); // Si la hora a la que se está creando el requerimiento es mayor a las 11:00 a.m.
            // entonces registrar como fecha de solicitud el día siguiente

            if (parseInt(fechaSolicitud.format('HH')) > 10) {
              fechaSolicitud = this.get('datesCalculator').getFechaLuegoDeDiasHabiles(fechaSolicitud, 1);
            } // La fecha de seguimiento programada se establece como el tercer día hábil
            // posterior a la fecha registrada de solicitud


            let fechaSeguimientoProgramada = this.get('datesCalculator').getFechaLuegoDeDiasHabiles(fechaSolicitud, 3);
            changeset.set('estatus', this.constants.REQUIREMENT_STATUS_VACANTE);
            changeset.set('fechaSolicitud', fechaSolicitud.toDate());
            changeset.set('fechaSeguimientoProgramada', fechaSeguimientoProgramada.toDate());
            changeset.set('esEditable', false);
            return this.controller.submit().then(() => {
              this.toast.success('Proceso de reclutamiento iniciado correctamente.');
            });
          }
        });
      },

      crearReproceso() {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro que quiere generar un reproceso?`,
          message: `Esto es un proceso irreversible`,
          onSubmit: () => {
            let changeset = this.controller.get('changeset');
            changeset.set('estatus', this.constants.REQUIREMENT_STATUS_REPROCESO);
            changeset.set('estaDeshabilitado', true); // El return no hace nada en este momento, pero lo ponemos por si en el
            // futuro se mejora el popup de confirmación para escuchar la promesa.

            return this.controller.submit().then(() => {
              this.toast.success('Requerimiento actual cerrado correctamente.');
              let requerimiento = this.modelFor('requerimiento.record').record;
              let fechaSolicitud = (0, _moment.default)(); // Si la hora a la que se está creando el requerimiento es mayor a las 11:00 a.m.
              // entonces registrar como fecha de solicitud el día siguiente

              if (parseInt(fechaSolicitud.format('HH')) > 10) {
                fechaSolicitud = this.get('datesCalculator').getFechaLuegoDeDiasHabiles(fechaSolicitud, 1);
              } // // Fecha seguimiento


              let fechaSeguimiento = this.get('datesCalculator').getFechaLuegoDeDiasHabiles(fechaSolicitud, 3);
              let requerimientoReproceso = this.store.createRecord('requerimiento', {
                esReprocesoDe: requerimiento,
                codigo: changeset.get('codigo').concat('R'),
                proyecto: changeset.get('proyecto'),
                fechaSolicitud: fechaSolicitud.toDate(),
                estatus: this.constants.REQUIREMENT_STATUS_BORRADOR,
                reclutador: changeset.get('reclutador'),
                plaza: changeset.get('plaza'),
                cantidadPlazas: changeset.get('cantidadPlazas'),
                tipoPlaza: changeset.get('tipoPlaza'),
                factorhPuestoStr: changeset.get('factorhPuestoStr'),
                nombrePlaza: changeset.get('nombrePlaza'),
                nombreJefeInmediato: changeset.get('nombreJefeInmediato'),
                supervisorOperativo: changeset.get('supervisorOperativo'),
                pais: changeset.get('pais'),
                departamento: changeset.get('departamento'),
                municipio: changeset.get('municipio'),
                ubicacion: changeset.get('ubicacion'),
                jornada: changeset.get('jornada'),
                diasHorario: changeset.get('diasHorario'),
                sustituye: changeset.get('sustituye'),
                fechaAltaProyecto: changeset.get('fechaAltaProyecto'),
                fechaSeguimientoProgramada: fechaSeguimiento.toDate(),
                esUrgente: changeset.get('esUrgente'),
                responsableSolicitud: changeset.get('responsableSolicitud'),
                responsablePuesto: changeset.get('responsablePuesto'),
                responsableCorreo: changeset.get('responsableCorreo'),
                responsableTelefono: changeset.get('responsableTelefono'),
                observacionesRecursos: changeset.get('observacionesRecursos'),
                observacionesComercial: changeset.get('observacionesComercial'),
                montoHonorario: changeset.get('montoHonorario') || 0,
                salarioBase: changeset.get('salarioBase') || 0,
                bonificacionDecreto: changeset.get('bonificacionDecreto') || 0,
                bonificacionIncenAdmin: changeset.get('bonificacionIncenAdmin') || 0,
                bonificacionIncen: changeset.get('bonificacionIncen') || 0,
                bonoTransporte: changeset.get('bonoTransporte') || 0,
                comisiones: changeset.get('comisiones') || 0,
                comisionesEspecificas: changeset.get('comisionesEspecificas') || 0,
                gasolina: changeset.get('gasolina') || 0,
                escolaridad: changeset.get('escolaridad'),
                idiomas: changeset.get('idiomas'),
                programasComputacion: changeset.get('programasComputacion'),
                anosExperiencia: changeset.get('anosExperiencia'),
                areasExperiencia: changeset.get('areasExperiencia'),
                edad: changeset.get('edad'),
                genero: changeset.get('genero'),
                estadoCivil: changeset.get('estadoCivil'),
                religion: changeset.get('religion'),
                vehiculo: changeset.get('vehiculo'),
                tipoLicencia: changeset.get('tipoLicencia'),
                infoVehiculo: changeset.get('infoVehiculo'),
                funcionesBasicas: changeset.get('funcionesBasicas'),
                competencias: changeset.get('competencias'),
                responsabilidades: changeset.get('responsabilidades'),
                personalBajoCargo: changeset.get('personalBajoCargo'),
                presentacion: changeset.get('presentacion')
              });
              return requerimientoReproceso.save().then(() => {
                this.toast.success('Requerimiento de reproceso creado correctamente.');
                this.transitionTo('requerimiento.record', requerimientoReproceso.id);
              }).catch(error => (0, _common.handleChangesetSaveErrors)(requerimientoReproceso, error, this.toast));
            });
          }
        });
      },

      marcarRequerimientoComoConfirmadoPendiente() {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de marcar como confirmado pendiente de ingreso el requerimiento?`,
          message: null,
          onSubmit: () => {
            let changeset = this.controller.get('changeset');
            changeset.set('fechaFinalizacion', new Date());
            changeset.set('estatus', this.constants.REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE);
            return this.controller.submit().then(() => {
              this.toast.success('Requerimiento marcado como confirmado pendiente correctamente.');
            });
          }
        });
      },

      marcarRequerimientoComoCongelado() {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de marcar como congelado el requerimiento?`,
          message: null,
          onSubmit: () => {
            let changeset = this.controller.get('changeset');
            changeset.set('estatus', this.constants.REQUIREMENT_STATUS_CONGELADO);
            changeset.set('estaDeshabilitado', true);
            return this.controller.submit().then(() => {
              this.toast.success('Requerimiento marcado como congelado correctamente.');
            });
          }
        });
      },

      marcarRequerimientoComoContratado() {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de marcar como contratado el requerimiento?`,
          message: null,
          onSubmit: () => {
            let changeset = this.controller.get('changeset');
            let aplicaciones = changeset.get('ternaActiva.aplicaciones');
            let aplicacionesContratadas = 0;
            aplicaciones.map(aplicacion => {
              if ((aplicacion.get('estatus') == this.constants.APPLICATION_STATUS_CONTRATADO || aplicacion.get('estatus') == this.constants.APPLICATION_STATUS_COMPLETADO) && aplicacion.get('seleccionado')) {
                aplicacionesContratadas += 1;
              }
            }); // Se comenta condicion ya que este permiso no deberia de obviar la condicion de contratar la misma cantidad
            // de aplicaciones que pide el requerimiento para contratar el requerimiento
            // if (!(this.get('currentUser').hasPermission('APPROVE_SPECIAL_TERNA') && changeset.get('esUrgente'))) {

            if (aplicacionesContratadas !== parseInt(changeset.get('cantidadPlazas'))) {
              this.toast.error('No han sido contratadas las aplicaciones necesarias.');
              return;
            } // }


            changeset.set('estatus', this.constants.REQUIREMENT_STATUS_CONTRATADO);
            changeset.set('estaDeshabilitado', true);
            changeset.set('fechaFinalizacion', new Date());
            return this.controller.submit().then(() => {
              this.toast.success('Requerimiento marcado como contratado correctamente.');
            });
          }
        });
      },

      descongelarRequerimiento() {
        let changeset = this.controller.get('changeset');
        changeset.set('estatus', this.constants.REQUIREMENT_STATUS_VACANTE);
        changeset.set('estaDeshabilitado', false);
        return this.controller.submit().then(() => {
          this.toast.success('Requerimiento descongelado correctamente.');
        });
      },

      reprogramarEntrevista() {
        let req = this.modelFor('requerimiento.record').record;
        let terna = req.get('ternaActiva');
        terna.set('reprogramar', true);
      },

      downloadPdf() {
        let requerimiento = this.controller.get('record');
        return this.getFileFromServer(`/requerimientos/${requerimiento.get('id')}/descarga_pdf`);
      },

      duplicarRequerimiento() {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro que desea duplicar el requerimiento?`,
          message: 'Esto lo rediccionará al nuevo requerimiento y se perderán los cambios no guardados del actual.',
          onSubmit: () => {
            let requerimiento = this.controller.get('record');
            this.loader.setIsLoading();
            this.ajaxService.request(`/requerimiento/${requerimiento.get('id')}/duplicar`).then(data => {
              let dataReturned = data.data;
              this.toast.success(dataReturned.message);
              this.transitionTo('requerimiento.record', dataReturned.new_requerimiento_id);
            }).catch(error => {
              this.toast.error('Ocurrió un error al intentar duplicar el requerimiento.');
              throw error;
            }).finally(() => this.loader.setNotLoading());
          }
        });
      },

      reasignarProyecto() {
        let requerimiento = this.controller.get('record');

        if (requerimiento.get('estatus') == this.constants.REQUIREMENT_STATUS_VACANTE || this.constants.REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE) {
          this.transitionTo('requerimiento.record.informacion.reasignar-proyecto');
        } else {
          this.toast.error('No es posible realizar esta acción en este momento..');
          return;
        }
      }

    }
  });

  _exports.default = _default;
});