define("ods-app/templates/requerimiento/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q5UEsTGO",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n  \"],[6,\"h1\"],[8],[0,\"\\n    Requerimientos de personal\\n\"],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelSortingProperties\",\"modelFilters\"],[[22,[\"columns\"]],[22,[\"modelName\"]],[22,[\"modelClass\"]],[22,[\"modelSortingProperties\"]],[22,[\"modelFilters\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n      \"],[6,\"h1\"],[8],[0,\"\\n        Requerimientos confirmados pendientes de ingreso\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelSortingProperties\",\"modelFilters\"],[[22,[\"columnsPendienteIngreso\"]],[22,[\"modelName\"]],[22,[\"modelClass\"]],[22,[\"modelSortingProperties\"]],[22,[\"modelFiltersPendientesIngreso\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/requerimiento/index.hbs"
    }
  });

  _exports.default = _default;
});