define("ods-app/templates/proyectos-existentes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vzAIPbm4",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n  \"],[6,\"h1\"],[8],[0,\"\\n    Proyectos\\n    \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n\"],[4,\"link-to\",[\"proyecto\"],[[\"class\"],[\"btn btn-success btn-xs\"]],{\"statements\":[[0,\"        \"],[6,\"i\"],[10,\"class\",\"fa fa-plus-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n        Crear proyecto\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelSortingProperties\",\"modelFilters\"],[[22,[\"columns\"]],\"Proyecto\",[22,[\"modelClass\"]],[22,[\"modelSortingProperties\"]],[22,[\"modelFilters\"]]]]],false],[0,\"\\n\\n  \"],[6,\"br\"],[8],[9],[0,\"\\n  \"],[6,\"br\"],[8],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n      \"],[6,\"h1\"],[8],[0,\"\\n        Proyectos de administración de planilla\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelSortingProperties\",\"modelFilters\"],[[22,[\"columnsAdminPlanilla\"]],\"Proyecto\",[22,[\"modelClass\"]],[22,[\"modelSortingProperties\"]],[22,[\"modelFiltersAdminPlanilla\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/proyectos-existentes/index.hbs"
    }
  });

  _exports.default = _default;
});