define("ods-app/controllers/requerimiento/record/informacion/cancelar-requerimiento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modelName: 'requerimiento',
    indexRoute: 'requerimiento.record.informacion',
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    user: Ember.computed.readOnly('currentUser.user'),
    actions: {
      onChange(razonCancelacion) {
        if (!razonCancelacion) {
          this.changeset.set('razonCancelacion', null);
          return;
        }

        this.set('razon', razonCancelacion);
        this.changeset.set('razonCancelacion', razonCancelacion);
      },

      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      },

      submitCancelacion() {
        if (!this.razon) {
          this.toast.error('Seleccione una razón de cancelación.');
          return;
        }

        this.loader.setIsLoading();
        this.changeset.set('fechaFinalizacion', new Date());
        this.changeset.set('estatus', this.constants.REQUIREMENT_STATUS_CANCELADO);
        this.changeset.set('estaDeshabilitado', true);
        this.changeset.set('fechaCancelacion', new Date());
        this.changeset.set('usuarioCancelacion', this.user);
        this.changeset.save().then(() => {
          this.toast.success('Requerimiento cancelado.');
          this.transitionToRoute(this.get('indexRoute'));
        }).catch(error => {
          let errorMsg = 'Ocurrió un error';

          if (error && error.message) {
            errorMsg = error.message;
          }

          this.toast.error(errorMsg);
        }).finally(() => this.loader.setNotLoading());
      }

    }
  });

  _exports.default = _default;
});