define("ods-app/templates/proyecto-historico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JfVpJ/1Q",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n  \"],[6,\"h1\"],[8],[0,\"\\n    Proyectos completados\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"modelSortingProperties\"],[[22,[\"columns\"]],\"Proyecto\",[22,[\"modelClass\"]],[22,[\"completedModelFilters\"]],[22,[\"modelSortingProperties\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/proyecto-historico.hbs"
    }
  });

  _exports.default = _default;
});