define("ods-app/helpers/has-config", ["exports", "ods-app/utils/app-utils"], function (_exports, _appUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    isTruthhy
  } = _appUtils.default;

  var _default = Ember.Helper.extend({
    currentUser: Ember.inject.service(),

    compute([configCode]) {
      const config = this.get('currentUser.configs').findBy('codigo', configCode);
      let value = false;

      if (!config) {
        return value;
      }

      if (!config.get('activo')) {
        return value;
      }

      if (config.get('valor')) {
        value = isTruthhy(config.get('valor'));
      }

      return value;
    }

  });

  _exports.default = _default;
});