define("ods-app/models/cliente", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    nombre: attr('string'),
    nombreComercial: attr('string'),
    nit: attr('string'),
    telefono: attr('string'),
    createdAt: attr('custom-date'),
    direccion: attr('string'),
    contactos: hasMany('cliente-contacto'),
    datosFacturacion: hasMany('cliente-dato-facturacion'),
    referencias: hasMany('cliente-referencia'),
    contratos: hasMany('cliente-contrato'),
    proyectos: hasMany('proyecto'),
    perfilesPuestos: hasMany('cliente-perfil-puesto'),
    fuenteReclutamiento: belongsTo('fuente-reclutamiento'),
    pais: belongsTo('pais'),
    departamento: belongsTo('departamento'),
    municipio: belongsTo('municipio'),
    factorhEmpresaId: attr('number'),
    factorhEmpresaStr: attr('string'),
    codigoNombre: Ember.computed('codigo', 'nombre', function () {
      return `${this.codigo} - ${this.nombre}`;
    })
  });

  _exports.default = _default;
});