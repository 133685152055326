define("ods-app/controllers/aplicacion/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/aplicacion"], function (_exports, _abstractModuleIndex, _aplicacion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'aplicacion',
    // includedRelationships: ['terna'],
    modelClass: _aplicacion.default,
    editRoute: 'aplicacion.record',
    reload: true,
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'createdAt'
      }]);
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: true,
        width: '100px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Candidato',
        valuePath: 'candidato.nombreCompleto',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'candidato.nombreCompleto'
      }, {
        label: 'Cliente Proyecto',
        valuePath: 'requerimiento.proyecto.cliente.nombre',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'requerimiento.proyecto.cliente.nombre'
      }, {
        label: 'Requerimiento',
        valuePath: 'requerimiento.nombrePlaza',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'requerimiento.nombrePlaza'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'estatus',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: this.constants.APPLICATION_STATUS
      }, // {
      //   label: 'Días activos',
      //   valuePath: 'diasActivos',
      //   sortable: false,
      //   width: '100px',
      // },
      {
        label: 'Fecha de contacto',
        valuePath: 'createdAtFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Expectativa salarial',
        valuePath: 'expectativaSalarialFormatted',
        sortable: false,
        width: '100px',
        align: 'right'
      }, {
        label: 'Fuente de reclutamiento',
        valuePath: 'fuenteReclutamiento.nombre',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'fuenteReclutamiento.nombre'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    })
  });

  _exports.default = _default;
});