define("ods-app/models/candidato", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    aplicaciones: hasMany('aplicacion'),
    codigo: attr('string'),
    primerNombre: attr('string'),
    segundoNombre: attr('string'),
    primerApellido: attr('string'),
    segundoApellido: attr('string'),
    apellidoCasada: attr('string'),
    uid: attr('string'),
    pasaporte: attr('string'),
    fechaNacimiento: attr('custom-date'),
    emails: attr(),
    telefonos: attr(),
    ocupacion: attr('string'),
    gradoAcademico: attr('string'),
    experiencia: attr('string'),
    archivos: hasMany('proyecto-archivo'),
    paisNacimiento: belongsTo('pais'),
    departamentoNacimiento: belongsTo('departamento'),
    municipioNacimiento: belongsTo('municipio'),
    paisVecindad: belongsTo('pais'),
    departamentoVecindad: belongsTo('departamento'),
    municipioVecindad: belongsTo('municipio'),
    observaciones: attr('string'),
    listaNegra: attr('boolean', {
      defaultValue: false
    }),
    disponible: attr('boolean', {
      defaultValue: true
    }),
    razonListaNegra: attr('string'),
    proyectoCandidatos: hasMany('proyecto-candidato'),
    factorhEmpleadoId: attr('number'),
    primerReq: Ember.computed('aplicaciones', function () {
      const primerReq = this.aplicaciones.get('firstObject');
      return primerReq;
    }),
    fechaNacimientoFormatted: Ember.computed('fechaNacimiento', function () {
      const momentDate = (0, _moment.default)(this.fechaNacimiento);

      if (Ember.isNone(this.fechaNacimiento) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    telefono: Ember.computed('telefonos', function () {
      return this.telefonos.firstObject;
    }),
    uidNombre: Ember.computed('uid', 'nombreCompleto', function () {
      return `${this.uid} - ${this.nombreCompleto}`;
    }),
    nombreCompleto: Ember.computed('primerNombre', 'segundoNombre', 'primerApellido', 'segundoApellido', function () {
      return `${this.primerNombre} ${this.segundoNombre || ''} ${this.primerApellido} ${this.segundoApellido || ''}`;
    })
  });

  _exports.default = _default;
});