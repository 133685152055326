define("ods-app/controllers/requerimiento/record/informacion-compensacion", ["exports", "servir-ember-utilities/controllers/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    editRoute: 'requerimiento.record.informacion-compensacion.planilla-cargo',
    planillaCargosColumns: Ember.computed('record.{esEditable,estaDeshabilitado}', function () {
      let columns = [{
        label: 'Cargo',
        valuePath: 'planillaCargo.nombre',
        width: '100px',
        sortable: false,
        titleValuePath: 'observaciones',
        cellComponent: 'content-with-title-cell'
      }, {
        label: 'Monto',
        valuePath: 'montoFormatted',
        width: '75px',
        sortable: false,
        align: 'right'
      }, {
        label: 'Desc. empleado',
        valuePath: 'generaDescuentoEmpleadoFormatted',
        width: '75px',
        sortable: false
      }];

      if (this.record.get('esEditable') && !this.record.get('estaDeshabilitado')) {
        columns.pushObject({
          label: 'Acciones',
          cellComponent: 'tables/cell/object-actions',
          table: this,
          sortable: false,
          width: '100px',
          // WARNING: Cuando se recomputan las columnas, la tabla no se actualiza.
          objectActions: {
            edit: {
              title: 'Editar',
              buttonClass: 'btn-primary',
              icon: 'pencil-square-o',
              action: this.actions.preEdit.bind(this)
            },
            delete: {
              title: 'Eliminar',
              buttonClass: 'btn-danger',
              icon: 'trash-o',
              action: this.actions.preDelete.bind(this)
            }
          }
        });
      }

      return Ember.A(columns);
    }),

    validarEsEditable() {
      if (this.record.get('noEsEditable') || this.record.get('estaDeshabilitado')) {
        this.toast.error('Ya no se puede modificar el requerimiento.');
        return false;
      }

      return true;
    },

    actions: {
      preEdit() {
        if (this.validarEsEditable()) {
          this.actions.edit.call(this, ...arguments);
        }
      },

      preDelete() {
        if (this.validarEsEditable()) {
          this.actions.delete.call(this, ...arguments);
        }
      }

    }
  });

  _exports.default = _default;
});