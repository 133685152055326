define("ods-app/routes/dashboard", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    userPermission: 'ViewDashboard',
    user: Ember.computed.alias('currentUser.user'),
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Tablero',
        route: 'dashboard'
      }];
    }),

    model() {
      let userId = this.user.get('id');

      if ('VIEW_ALL_REQUIREMENTS_DASHBOARD' in this.user.get('permissions')) {
        userId = null;
      }

      let queries = {
        requerimientosEnSeguimiento: this.store.query('requerimiento', {
          filter: {
            reclutador: userId,
            'custom.enSeguimiento': 1,
            estatus: [this.constants.REQUIREMENT_STATUS_VACANTE, this.constants.REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE, this.constants.REQUIREMENT_STATUS_CONGELADO],
            esReprocesoDe: {
              exists: 0
            }
          },
          include: 'proyecto, proyecto.cliente'
        }),
        requerimientoEnTerna: this.store.query('requerimiento', {
          filter: {
            reclutador: userId,
            'custom.enSeguimiento': 0,
            estatus: [this.constants.REQUIREMENT_STATUS_VACANTE, this.constants.REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE, this.constants.REQUIREMENT_STATUS_CONGELADO],
            esReprocesoDe: {
              exists: 0
            }
          },
          include: 'proyecto, proyecto.cliente'
        }),
        requerimientosDeReproceso: this.store.query('requerimiento', {
          filter: {
            reclutador: userId,
            estatus: [this.constants.REQUIREMENT_STATUS_VACANTE, this.constants.REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE, this.constants.REQUIREMENT_STATUS_CONGELADO],
            esReprocesoDe: {
              exists: 1
            }
          },
          include: 'proyecto, proyecto.cliente'
        })
      };
      return Ember.RSVP.hash(queries).then(data => {
        let clientesIds = Ember.A();
        clientesIds.addObjects(data.requerimientosEnSeguimiento.mapBy('proyecto.content.cliente.id'));
        clientesIds.addObjects(data.requerimientoEnTerna.mapBy('proyecto.content.cliente.id'));
        clientesIds.addObjects(data.requerimientosDeReproceso.mapBy('proyecto.content.cliente.id'));

        if (!clientesIds.length) {
          return data;
        }

        return this.store.query('cliente', {
          filter: {
            id: clientesIds.uniq()
          }
        }).then(() => data);
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties(model);
    }

  });

  _exports.default = _default;
});