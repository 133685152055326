define("ods-app/controllers/candidatos-existentes/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/candidato"], function (_exports, _abstractModuleIndex, _candidato) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'candidato',
    modelClass: _candidato.default,
    editRoute: 'candidato.record',
    reload: true,
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '70px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Nombre',
        valuePath: 'nombreCompleto',
        sortable: false,
        width: '130px',
        filtrable: true,
        filterName: 'nombreCompleto'
      }, {
        label: 'DPI/Pasaporte',
        valuePath: 'uid',
        sortable: false,
        width: '90px',
        filtrable: true,
        filterName: 'uid'
      }, {
        label: 'Fecha de nacimiento',
        valuePath: 'fechaNacimientoFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Correos electrónicos',
        valuePath: 'emails',
        sortable: false,
        width: '100px'
      }, {
        label: 'teléfonos',
        valuePath: 'telefonos',
        sortable: false,
        width: '100px'
      }, {
        label: 'Ocupación',
        valuePath: 'ocupacion',
        sortable: false,
        filtrable: true,
        width: '100px',
        filterName: 'ocupacion'
      }, {
        label: 'Cantidad de aplicaciones',
        valuePath: 'aplicaciones.length',
        sortable: false,
        width: '100px'
      }, {
        label: 'Requerimientos asociados',
        valuePath: 'primerReq.requerimiento.nombrePlaza',
        sortable: false,
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    })
  });

  _exports.default = _default;
});