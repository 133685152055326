define("ods-app/controllers/requerimiento-historico", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/requerimiento"], function (_exports, _abstractModuleIndex, _requerimiento) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'requerimiento',
    modelClass: _requerimiento.default,
    editRoute: 'requerimiento.record',
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'fechaSolicitud'
      }]);
    }),
    completedModelFilters: Ember.computed(function () {
      return [{
        selectedFilter: {
          filterName: 'estatus'
        },
        value: this.constants.REQUIREMENT_STATUS_CONTRATADO
      }];
    }),
    reprocessingModelFilters: Ember.computed(function () {
      return [{
        selectedFilter: {
          filterName: 'estatus'
        },
        value: this.constants.REQUIREMENT_STATUS_REPROCESO
      }];
    }),
    canceledModelFilters: Ember.computed(function () {
      return [{
        selectedFilter: {
          filterName: 'estatus'
        },
        value: this.constants.REQUIREMENT_STATUS_CANCELADO
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '90px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Fecha de solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px'
      }, {
        label: 'Reclutador',
        valuePath: 'reclutador.username',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'reclutador'
      }, {
        label: 'Proyecto',
        valuePath: 'proyecto.cliente.nombre',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'proyecto.cliente.nombre'
      }, {
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: false,
        width: '160px',
        filtrable: true,
        filterName: 'nombrePlaza'
      }, {
        label: 'Fecha de seguimiento',
        valuePath: 'fechaSeguimientoProgramadaFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Fecha vencimiento',
        valuePath: 'ternaActiva.fechaVencimientoEnvioFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          }
        }
      }]);
    }),
    columnsCancelados: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '90px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Fecha de solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px'
      }, {
        label: 'Reclutador',
        valuePath: 'reclutador.username',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'reclutador'
      }, {
        label: 'Proyecto',
        valuePath: 'proyecto.cliente.nombre',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'proyecto.cliente.nombre'
      }, {
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: false,
        width: '160px',
        filtrable: true,
        filterName: 'nombrePlaza'
      }, {
        label: 'Razón de cancelación',
        valuePath: 'razonCancelacion.nombre',
        sortable: false,
        width: '150px'
      }, {
        label: 'Cancelado por',
        valuePath: 'usuarioCancelacion.username',
        sortable: false,
        width: '150px'
      }, {
        label: 'Fecha de cancelación',
        valuePath: 'fechaCancelacionFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          }
        }
      }]);
    })
  });

  _exports.default = _default;
});