define("ods-app/controllers/requerimiento/record/informacion/reasignar-proyecto", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexRoute: 'requerimiento.record.informacion',
    nuevoProyecto: null,
    actions: {
      onChange(proyecto) {
        if (!proyecto) {
          return;
        }

        this.changeset.set('proyecto', proyecto);
      },

      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      },

      searchProjects(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.contactsQuery) {
          Ember.run.cancel(this.contactsQuery);
        }

        this.contactsQuery = Ember.run.later(() => {
          this.set('availableProjects', this.store.query('proyecto', {
            filter: {
              'estatus': this.constants.PROJECT_STATUS_ACTIVE,
              nombreProyecto: text
            },
            include: 'servicioAdquirido,clienteContacto'
          }));
        }, 500);
      }

    }
  });

  _exports.default = _default;
});