define("ods-app/routes/cliente/record/datos-generales/factorh-empresa", ["exports", "ods-app/mixins/permission-validator"], function (_exports, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionValidator.default, {
    ajaxService: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    routePermission: 'EDIT_CLIENT_FACTORH_COMPANY',
    indexRoute: 'cliente.record.datos-generales',

    model() {
      this.loader.setIsLoading();
      return this.ajaxService.request('/factorh-empresas').then(({
        data: factorhEmpresas
      }) => ({
        factorhEmpresas
      })).catch(error => {
        this.toast.error('Ocurrió un error al obtener la lista de empresas.');
        this.transitionTo(this.indexRoute);
        throw error;
      }).finally(() => this.loader.setNotLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = this.controllerFor(this.indexRoute).get('changeset');
      controller.set('factorhEmpresa', null); // Set the controller variable to show the current 'factorhEmpresa'.

      if (changeset.get('factorhEmpresaId')) {
        const factorhEmpresa = model.factorhEmpresas.findBy('id', changeset.get('factorhEmpresaId'));

        if (factorhEmpresa) {
          controller.set('factorhEmpresa', factorhEmpresa);
        } else {
          this.toast.error('El departamento actual asignado ya no existe en FactoRH.');
        }
      }

      controller.setProperties({
        changeset,
        record: this.modelFor(this.indexRoute).record
      });
    }

  });

  _exports.default = _default;
});