define("ods-app/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "moment"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    datesCalculator: Ember.inject.service(),
    user: Ember.computed.alias('currentUser.user'),

    beforeModel(transition) {
      _moment.default.locale('es');

      if (transition.targetName == 'logout') {
        return;
      } // Si la transición va hacia la ruta de autenticación, go on. De lo contrario
      // disparar la verificación de autenticación


      if (!this.session.isAuthenticated && transition.targetName === this.authenticationRoute) {
        return;
      }

      if (!this.session.isAuthenticated) {
        return this.transitionTo(this.authenticationRoute);
      }

      return this._loadCurrentUser().then(() => {
        // WARNGING: Returning transition doesn't work.
        if (!this.get('user.passwordResetted')) {
          this.transitionTo('reset-password');
          return;
        }

        this._super(transition);
      });
    },

    /**
     * @private
     */
    _loadCurrentUser() {
      return this.get('currentUser').load() // Luego de cargar al usuario inicializar este servicio para cargar los días
      // festivos almacenados en FactoRH
      .then(() => this.get('datesCalculator').load()).catch(e => {
        this.session.invalidate();
        throw e;
      });
    },

    /**
     * @inherit
     *
     * After session gets authenticated, load the current user.
     */
    sessionAuthenticated() {
      const callSuper = this._super.bind(this);

      this._loadCurrentUser().then(() => {
        let user = this.get('user');
        let transitionRoute = 'dashboard';

        if (!user.get('passwordResetted')) {
          transitionRoute = 'reset-password';
        }

        this.set('routeAfterAuthentication', transitionRoute);
        callSuper();
      });
    },

    /**
     * @private
     */
    renderModal(template) {
      this.render(template, {
        outlet: 'modal',
        into: 'application'
      });
    },

    actions: {
      /**
       * @private
       */
      closeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },

      openModal(modalTemplatePath, controllerProperties) {
        if (!Ember.isNone(controllerProperties)) {
          Ember.setProperties(this.controllerFor(modalTemplatePath), controllerProperties);
        }

        this.renderModal(modalTemplatePath);
      }

    }
  });

  _exports.default = _default;
});