define("ods-app/controllers/requerimiento/record/entrevista", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/aplicacion", "ods-app/models/entrevista"], function (_exports, _abstractModuleIndex, _aplicacion, _entrevista) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelClass: _aplicacion.default,
    entrevistaModelClass: _entrevista.default,
    // editRoute: 'aplicacion.record',
    editRoute: 'requerimiento.record.entrevista.record',
    reload: true,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'requerimiento'
        },
        value: this.get('record.id') || -1
      }, {
        selectedFilter: {
          filterName: 'custom.estatusHistorico'
        },
        value: this.constants.APPLICATION_STATUS_EN_ENTREVISTA
      }];
    }),
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'id'
      }]);
    }),
    columns: Ember.computed(function () {
      let currentStatus = [this.constants.APPLICATION_STATUS_EN_ENTREVISTA, this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA];
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: true,
        width: '100px',
        filtrable: true,
        filterName: 'codigo',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Candidato',
        valuePath: 'candidato.nombreCompleto',
        sortable: true,
        sortingPropertyName: 'candidato.primerNombre',
        width: '100px',
        filtrable: true,
        filterName: 'candidato.primerNombre',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Ocupación',
        valuePath: 'candidato.ocupacion',
        sortable: true,
        width: '100px',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Expectativa salarial',
        valuePath: 'expectativaSalarialFormatted',
        sortable: false,
        width: '100px',
        align: 'right',
        filtrable: true,
        filterName: 'expectativaSalarial',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: true,
        sortingPropertyName: 'estatus',
        width: '100px',
        filtrable: true,
        filterName: 'estatus',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar aplicación',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.editarAplicacion.bind(this)
          },
          crearEntrevista: {
            title: 'Nueva entrevista',
            buttonClass: 'btn-warning',
            icon: 'plus-square',
            action: this.actions.crearEntrevista.bind(this)
          },
          mandarAplicacionAPruebas: {
            title: 'Mandar a pruebas',
            buttonClass: 'btn-success',
            icon: 'arrow-left',
            action: this.actions.mandarAPruebas.bind(this)
          },
          enviarATerna: {
            title: 'Enviar a terna activa',
            buttonClass: 'btn-success',
            icon: 'share',
            action: this.actions.asignarAplicacionATernaActiva.bind(this)
          },
          rechazar: {
            title: 'Rechazar aplicación',
            buttonClass: 'btn-danger',
            icon: 'times',
            action: this.actions.rechazarAplicacion.bind(this)
          }
        }
      }]);
    }),
    entrevistasColumns: Ember.computed(function () {
      return Ember.A([{
        label: 'Candidato',
        valuePath: 'aplicacion.candidato.nombreCompleto',
        sortable: false,
        width: '100px'
      }, {
        label: 'Fecha',
        valuePath: 'fechaFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px'
      }, {
        label: 'Tipo',
        valuePath: 'tipoToString',
        sortable: false,
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),
    entrevistasModelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'aplicacion.requerimiento'
        },
        value: this.get('record.id') || -1
      }];
    }),

    reloadModel() {
      this.set('reload', true);
    },

    actions: {
      crearEntrevista(aplicacion) {
        const aplicacionId = aplicacion.get('id');
        const requerimientoId = aplicacion.get('requerimiento.id');
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error('El requerimiento está deshabilitado');
          return;
        } else if (aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA) {
          this.toast.error('Esta aplicación está rechazada, no puede realizar ningún cambio.');
          return;
        }

        this.transitionToRoute('requerimiento.record.entrevista.record', 'new', {
          queryParams: {
            'aplicacionId': aplicacionId,
            'requerimientoId': requerimientoId
          }
        });
      },

      asignarAplicacionATernaActiva(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error('El requerimiento está deshabilitado');
          return;
        } else if (aplicacion.get('terna.content')) {
          this.toast.error('La aplicacion ya pertenece a una terna.');
          return;
        } else if (controller.get('record.ternas.length') == 0) {
          this.toast.error('No hay ninguna terna asociada al requerimiento.');
          return;
        } else if (aplicacion.estatus === this.constants.APPLICATION_STATUS_RECHAZADO_EN_PRUEBAS || aplicacion.estatus === this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA) {
          this.toast.error('Esta aplicación está rechazada, no puede realizar ningún cambio.');
          return;
        }

        let ternaActiva = controller.get('record.ternas').filterBy('activo').get('firstObject');

        if (!ternaActiva) {
          this.toast.error('Todavía no se ha iniciado el proceso de reclutamiento.');
          return;
        }

        if (ternaActiva.get('aceptada')) {
          this.toast.error('la terna ya ha sido aceptada, no puede realizar ningún cambio.');
          return;
        }

        aplicacion.set('terna', ternaActiva);
        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_EN_TERNA);
        aplicacion.save().then(() => {
          this.reloadModel();
          this.toast.success('Aplicación agregada a terna activa');
        });
      },

      mandarAPruebas(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error('El requerimiento está deshabilitado');
          return;
        } else if (aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA) {
          this.toast.error('Esta aplicación está rechazada, no puede realizar ningún cambio.');
          return;
        } else if (aplicacion.get('terna.content')) {
          this.toast.error('La aplicacion ya pertenece a una terna.');
          return;
        }

        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_PRUEBAS);
        aplicacion.save().then(() => {
          this.reloadModel();
          this.toast.success('Aplicación enviada a pruebas');
        });
      },

      rechazarAplicacion(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error('El requerimiento está deshabilitado');
          return;
        } else if (aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA) {
          this.toast.error('Esta aplicación está rechazada, no puede realizar ningún cambio.');
          return;
        }

        this.send('openModal', 'popups/enviar-lista-negra', {
          title: 'Rechazar aplicación',
          message: '¿Desea enviar al candidato a lista negra?',
          buttonActions: [{
            title: 'Enviar',
            methodName: 'enviarAListaNegra',
            buttonType: 'warning'
          }, {
            title: 'No enviar',
            methodName: 'noEnviarAListaNegra',
            buttonType: 'submit'
          }],
          enviarAListaNegra: razonListaNegra => {
            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA);
            aplicacion.save().then(() => aplicacion.get('candidato')).then(candidato => {
              candidato.set('listaNegra', true);
              candidato.set('razonListaNegra', razonListaNegra);
              candidato.save().then(() => {
                this.reloadModel();
                this.toast.success('Aplicación rechazada');
              });
            });
          },
          noEnviarAListaNegra: () => {
            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA);
            aplicacion.save().then(() => {
              this.reloadModel();
              this.toast.success('Aplicación rechazada');
            });
          }
        });
      },

      editarAplicacion(aplicacion) {
        let editRoute = 'aplicacion.record'; // If the item is new, transition with the whole object (wont trigger the
        // model hook), otherwise transition with its id (will trigger the model hook)

        if (Ember.isNone(aplicacion.get('id'))) {
          this.transitionToRoute(editRoute, aplicacion);
          return;
        }

        this.transitionToRoute(editRoute, aplicacion.get('id'));
      }

    }
  });

  _exports.default = _default;
});