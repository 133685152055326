define("ods-app/templates/proyecto/record/cliente", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jlP7ftkW",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-xs-6\"],[8],[0,\"\\n      \"],[6,\"h4\"],[8],[0,\"\\n        Cliente\\n      \"],[9],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"cliente.codigo\",\"Código:\"],[[\"disabled\"],[true]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"cliente.nombre\",\"Nombre:\"],[[\"disabled\"],[true]]],false],[0,\"\\n\\n      \"],[6,\"h4\"],[8],[0,\"\\n        Contacto\\n      \"],[9],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"clienteContacto.nombre\",\"Nombre:\"],[[\"disabled\"],[true]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"clienteContacto.telefono\",\"Teléfono:\"],[[\"disabled\"],[true]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"clienteContacto.correo\",\"Correo electrónico:\"],[[\"disabled\"],[true]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"clienteContacto.puesto\",\"Puesto:\"],[[\"disabled\"],[true]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-xs-6\"],[8],[0,\"\\n      \"],[6,\"h4\"],[8],[0,\"\\n        Información de facturación\\n      \"],[9],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"clienteDatoFacturacion.nit\",\"NIT:\"],[[\"disabled\"],[true]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"clienteDatoFacturacion.razonSocial\",\"Razón social:\"],[[\"disabled\"],[true]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"clienteDatoFacturacion.direccionFiscal\",\"Dirección fiscal:\"],[[\"disabled\"],[true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/proyecto/record/cliente.hbs"
    }
  });

  _exports.default = _default;
});