define("ods-app/validations/changeset/candidato", ["exports", "ember-changeset-validations/validators", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/list-of-emails", "servir-ember-utilities/validators/is-codigo"], function (_exports, _validators, _customPresence, _listOfEmails, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    codigo: [(0, _customPresence.default)({
      presence: true
    }), (0, _isCodigo.default)()],
    primerNombre: (0, _validators.validatePresence)({
      presence: true
    }),
    primerApellido: (0, _validators.validatePresence)({
      presence: true
    }),
    fechaNacimiento: (0, _validators.validatePresence)({
      presence: true
    }),
    ocupacion: (0, _validators.validatePresence)({
      presence: true
    }),
    emails: [(0, _validators.validateLength)({
      min: 1
    }), (0, _listOfEmails.default)(), (0, _validators.validatePresence)({
      presence: true
    })],
    telefonos: [(0, _validators.validateLength)({
      min: 1
    }), (0, _validators.validatePresence)({
      presence: true
    })],
    paisVecindad: (0, _validators.validatePresence)({
      presence: true
    }),
    departamentoVecindad: (0, _validators.validatePresence)({
      presence: true
    }),
    municipioVecindad: (0, _validators.validatePresence)({
      presence: true
    }),
    paisNacimiento: (0, _validators.validatePresence)({
      presence: true
    }),
    departamentoNacimiento: (0, _validators.validatePresence)({
      presence: true
    }),
    municipioNacimiento: (0, _validators.validatePresence)({
      presence: true
    })
  };
  _exports.default = _default;
});