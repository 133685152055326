define("ods-app/controllers/requerimiento/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    postSaveTransitionRoute: null,
    subRoutesFieldsMapping: Ember.computed(function () {
      return {
        informacion: ['codigo', 'proyecto', 'fechaSolicitud', 'estatus', 'reclutador', 'plaza', 'tipoPlaza', 'nombrePlaza', 'pais', 'departamento', 'municipio', 'nombreJefeInmediato', 'jornada', 'cantidadPlazas', 'supervisorOperativo', 'ubicacion', 'diasHorario', 'sustituye', 'esUrgente'],
        perfil: ['escolaridad', 'idiomas', 'programasComputacion', 'anosExperiencia', 'areasExperiencia', 'edad', 'genero', 'estadoCivil', 'religion', 'vehiculo', 'tipoLicencia', 'infoVehiculo', 'funcionesBasicas', 'competencias', 'responsabilidades', 'personalBajoCargo', 'presentacion'],
        'informacion-compensacion': ['salarioBase', 'bonificacionDecreto', 'montoHonorario', 'bonificacionIncenAdmin', 'bonificacionIncen', 'bonoTransporte', 'comisiones', 'comisionesEspecificas', 'gasolina'],
        aplicacion: ['fechaSeguimientoProgramada', 'fechaSeguimiento', 'responsable', 'horaContacto']
      };
    }),

    resetProperties() {
      this.setProperties({
        paises: [],
        departamentos: [],
        municipios: [],
        proyectos: [],
        usuarios: [],
        candidatos: [],
        aplicaciones: []
      });
    },

    postSave(record) {
      if (this.get('isNew')) {
        // TODO: Research about the best way to update the URL with record id.
        return this.transitionToRoute(this.get('routeName'), record.id);
      }
    }

  });

  _exports.default = _default;
});