define("ods-app/routes/proyecto/record/servicio/factorh-departamento", ["exports", "ods-app/mixins/permission-validator"], function (_exports, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionValidator.default, {
    ajaxService: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    routePermission: 'EDIT_PROJECT_FACTORH_DEPARTAMENT',
    indexRoute: 'proyecto.record.servicio',

    model() {
      this.loader.setIsLoading();
      return this.ajaxService.request('/factorh-departamentos').then(({
        data: factorhDepartamentos
      }) => ({
        factorhDepartamentos
      })).catch(error => {
        this.toast.error('Ocurrió un error al obtener la lista de departamentos.');
        this.transitionTo(this.indexRoute);
        throw error;
      }).finally(() => this.loader.setNotLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = this.controllerFor(this.indexRoute).get('changeset');
      controller.set('factorhDepartamento', null); // Set the controller variable to show the current 'factorhDepartamento'.

      if (changeset.get('factorhDepartamentoId')) {
        const factorhDepartamento = model.factorhDepartamentos.findBy('id', changeset.get('factorhDepartamentoId'));

        if (factorhDepartamento) {
          controller.set('factorhDepartamento', factorhDepartamento);
        } else {
          this.toast.error('El departamento actual asignado ya no existe en FactoRH.');
        }
      }

      controller.setProperties({
        changeset,
        record: this.modelFor(this.indexRoute).record
      });
    }

  });

  _exports.default = _default;
});