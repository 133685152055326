define("ods-app/controllers/requerimiento/record/informacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    loader: Ember.inject.service(),
    ajaxService: Ember.inject.service(),
    factorhPuestoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editFactorhPuesto.bind(this)
      }];
    }),
    // fechaInicioLaboresHabilitado: computed(function() {
    //   return this.store.query('config', {
    //     filter: {
    //       codigo: 'FECHA_INICIO_LABORES_REQUERIMIENTO',
    //     }
    //   })
    //   .then((data) => {
    //     let config = data.get('firstObject');
    //     let valor = config.get('valor');
    //     if(!config.get('activo')) {
    //       return false;
    //     }
    //     return valor;
    //   });
    // }),
    // esUrgenteHabilitado: computed(function() {
    //   return this.store.query('config', {
    //     filter: {
    //       codigo: 'ES_URGENTE_REQUERIMIENTO',
    //     }
    //   })
    //   .then((data) => {
    //     let config = data.get('firstObject');
    //     let valor = config.get('valor');
    //     if(!config.get('activo')) {
    //       return false;
    //     }
    //     return valor;
    //   });
    // }),
    actions: {
      editFactorhPuesto() {
        if (this.record.get('isNew')) {
          this.toast.error('Guarde el registro para poder realizar esta acción.');
          return;
        }

        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Corrija los errores para poder realizar esta acción.');
            return;
          }

          this.transitionToRoute('requerimiento.record.informacion.factorh-puesto');
        });
      },

      onChangeProyecto(proyecto) {
        if (!proyecto) {
          return;
        }

        let clienteContacto = proyecto.get('clienteContacto');

        if (this.changeset.get('plaza') === this.constants.JOB_TYPES_EXISTENTE || this.changeset.get('plaza') === this.constants.JOB_TYPES_SUSTITUCION_TEMPORAL && proyecto.get('servicioAdquirido.tipoServicio') === this.constants.SERVICE_TYPES_PAYROLL_ADMINISTRATION_FACTORH) {
          this.loader.setIsLoading();
          this.ajaxService.request(`/cliente/${proyecto.get('cliente.id')}/proyecto-candidato-aplicacion`).then(data => {
            this.set('proyectoCandidatosYAplicaciones', data);
          }).catch(error => {
            this.toast.error('Ocurrió un error al obtener los candidatos.');
            throw error;
          }).finally(() => this.loader.setNotLoading());
        }

        if (!this.changeset.get('fechaInicioLabores')) {
          this.changeset.set('fechaInicioLabores', proyecto.get('fechaAlta'));
        }

        this.changeset.set('responsableSolicitud', clienteContacto.get('nombre'));
        this.changeset.set('responsablePuesto', clienteContacto.get('puesto'));
        this.changeset.set('responsableCorreo', clienteContacto.get('correo'));
        this.changeset.set('responsableTelefono', clienteContacto.get('telefono'));
      },

      searchProjects(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.contactsQuery) {
          Ember.run.cancel(this.contactsQuery);
        }

        this.contactsQuery = Ember.run.later(() => {
          this.set('availableProjects', this.store.query('proyecto', {
            filter: {
              'estatus': this.constants.PROJECT_STATUS_ACTIVE,
              'servicioAdquirido.tipoServicio': [this.constants.SERVICE_TYPES_HIRED, this.constants.SERVICE_TYPES_HIRED_FACTORH, this.constants.SERVICE_TYPES_PAYROLL_ADMINISTRATION_FACTORH],
              nombreProyecto: text
            },
            include: 'servicioAdquirido,clienteContacto'
          }));
        }, 500);
      },

      onChangePlaza(proxy, propiedad, changedTo) {
        let proyecto = this.changeset.get('proyecto');
        this.changeset.set('plaza', changedTo);

        if (!proyecto.get('id')) {
          return;
        }

        if (changedTo === this.constants.JOB_TYPES_EXISTENTE || changedTo === this.constants.JOB_TYPES_SUSTITUCION_TEMPORAL && proyecto.get('servicioAdquirido.tipoServicio') === this.constants.SERVICE_TYPES_PAYROLL_ADMINISTRATION_FACTORH) {
          this.loader.setIsLoading();
          this.ajaxService.request(`/cliente/${proyecto.get('cliente.id')}/proyecto-candidato-aplicacion`).then(data => {
            this.set('proyectoCandidatosYAplicaciones', data);
          }).catch(error => {
            this.toast.error('Ocurrió un error al obtener los candidatos.');
            throw error;
          }).finally(() => this.loader.setNotLoading());
        }
      }

    }
  });

  _exports.default = _default;
});