define("ods-app/models/notification-config", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    code: attr('string'),
    name: attr('string'),
    subjectTemplate: attr('string'),
    bodyTemplate: attr('string'),
    event: attr('string'),
    entityName: attr('string'),
    entityVariableName: attr('string'),
    propertyName: attr('string'),
    propertyNewValue: attr('string'),
    enabled: attr('boolean', {
      defaultValue: true
    }),
    cannotBeRemoved: attr('boolean'),
    roles: hasMany('role'),
    enabledString: Ember.computed('enabled', function () {
      return this.enabled ? 'Sí' : 'No';
    }),
    eventString: Ember.computed('event', function () {
      if (!this.event) {
        return null;
      }

      const eventString = this.constants.NOTIFICATION_CONFIG_EVENTS.findBy('key', this.event);
      return eventString ? eventString.name : this.event;
    })
  });

  _exports.default = _default;
});