define("ods-app/models/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    SERVICE_TYPES: [{
      key: 'CON_RECLUTAMIENTO_SELECCION',
      name: 'Con reclutamiento y selección'
    }, {
      key: 'CON_RECLUTAMIENTO_SELECCION_FACTORH',
      name: 'Con reclutamiento y selección para FactoRH'
    }, {
      key: 'SIN_RECLUTAMIENTO_SELECCION',
      name: 'Sin reclutamiento y selección'
    }, {
      key: 'ADMINISTRACION_PLANILLA_FACTORH',
      name: 'Administración de planilla FactoRH'
    }],
    SERVICE_TYPES_HIRED: 'CON_RECLUTAMIENTO_SELECCION',
    SERVICE_TYPES_HIRED_FACTORH: 'CON_RECLUTAMIENTO_SELECCION_FACTORH',
    SERVICE_TYPES_NO_HIRING: 'SIN_RECLUTAMIENTO_SELECCION',
    SERVICE_TYPES_PAYROLL_ADMINISTRATION_FACTORH: 'ADMINISTRACION_PLANILLA_FACTORH',
    ROLE_GERENCIA_RH_CODE: 'GerenciaRH',
    REQUIREMENT_SOURCE_TYPES: [{
      key: '',
      name: 'Seleccionar'
    }, {
      key: 'CLIENTES',
      name: 'Clientes'
    }, {
      key: 'CANDIDATOS',
      name: 'Candidatos'
    }],
    REQUIREMENT_SOURCE_TYPE_CLIENTE: 'CLIENTES',
    REQUIREMENT_SOURCE_TYPE_CANDIDATO: 'CANDIDATOS',
    CANCELLATION_REASON_SOURCE_TYPES: [{
      key: '',
      name: 'Seleccionar'
    }, {
      key: 'PROYECTOS',
      name: 'Proyectos'
    }, {
      key: 'REQUERIMIENTOS',
      name: 'Requerimientos'
    }],
    CANCELLATION_REASON_SOURCE_TYPE_PROYECTO: 'PROYECTOS',
    CANCELLATION_REASON_SOURCE_TYPE_REQUERIMIENTO: 'REQUERIMIENTOS',
    REQUIREMENT_SITUATION_EN_TIEMPO: 'EN_TIEMPO',
    REQUIREMENT_SITUATION_FUERA_DE_TIEMPO: 'FUERA_DE_TIEMPO',
    PROJECT_STATUS: [{
      key: 'ACTIVO',
      name: 'Activo'
    }, {
      key: 'FACTURADO',
      name: 'Facturado'
    }, {
      key: 'COMPLETADO',
      name: 'Completado'
    }, {
      key: 'ANULADO',
      name: 'Anulado'
    }, {
      key: 'EN_FACTURACION',
      name: 'En facturación'
    }],
    PROJECT_STATUS_ACTIVE: 'ACTIVO',
    PROJECT_STATUS_INVOICED: 'FACTURADO',
    PROJECT_STATUS_COMPLETED: 'COMPLETADO',
    PROJECT_STATUS_BILLING: 'EN_FACTURACION',
    PROJECT_STATUS_CANCELED: 'ANULADO',
    REQUIREMENT_STATUS: [{
      key: 'BORRADOR',
      name: 'Borrador'
    }, {
      key: 'VACANTE',
      name: 'Vacante'
    }, {
      key: 'CONFIRMADO_PENDIENTE_DE_INGRESO',
      name: 'Confirmado pediente de ingreso'
    }, {
      key: 'CONTRATADO',
      name: 'Contratado'
    }, {
      key: 'CONGELADO',
      name: 'Congelado'
    }, {
      key: 'CANCELADO',
      name: 'Cancelado'
    }, {
      key: 'REPROCESO',
      name: 'Reproceso'
    }],
    REQUIREMENT_STATUS_BORRADOR: 'BORRADOR',
    REQUIREMENT_STATUS_VACANTE: 'VACANTE',
    REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE: 'CONFIRMADO_PENDIENTE_DE_INGRESO',
    REQUIREMENT_STATUS_CONTRATADO: 'CONTRATADO',
    REQUIREMENT_STATUS_CONGELADO: 'CONGELADO',
    REQUIREMENT_STATUS_CANCELADO: 'CANCELADO',
    REQUIREMENT_STATUS_REPROCESO: 'REPROCESO',
    JOB_TYPES: [{
      key: 'NUEVA',
      name: 'Nueva'
    }, {
      key: 'EXISTENTE',
      name: 'Existente'
    }, {
      key: 'SUSTITUCION_TEMPORAL',
      name: 'Sustitución temporal'
    }],
    JOB_TYPES_NUEVA: 'NUEVA',
    JOB_TYPES_EXISTENTE: 'EXISTENTE',
    JOB_TYPES_SUSTITUCION_TEMPORAL: 'SUSTITUCION_TEMPORAL',
    HIRING_TYPES: [{
      key: 'FACTURACION',
      name: 'Facturación interna'
    }, {
      key: 'FACTURA_ESPECIAL',
      name: 'Factura especial interna'
    }, {
      key: 'FACTURACION_EXTERNA',
      name: 'Factura externa'
    }, {
      key: 'PLANILLA',
      name: 'Planilla'
    }],
    HIRING_TYPES_FACTURACION: 'FACTURACION',
    HIRING_TYPES_FACTURA_ESPECIAL: 'FACTURA_ESPECIAL',
    HIRING_TYPES_FACTURACION_EXTERNA: 'FACTURACION_EXTERNA',
    HIRING_TYPES_PLANILLA: 'PLANILLA',
    APPLICATION_STATUS: [{
      key: 'PRE_SCREENING',
      name: 'Pre screening'
    }, {
      key: 'RECHAZADO',
      name: 'Rechazado'
    }, {
      key: 'PRUEBAS',
      name: 'Pruebas'
    }, {
      key: 'RECHAZADO_EN_PRUEBAS',
      name: 'Rechazado en pruebas'
    }, {
      key: 'EN_ENTREVISTA',
      name: 'En entrevista'
    }, {
      key: 'RECHAZADO_EN_ENTREVISTA',
      name: 'Rechazado en entrevista'
    }, {
      key: 'EN_TERNA',
      name: 'En terna'
    }, {
      key: 'CONFIRMADO_PENDIENTE_DE_INGRESO',
      name: 'Confirmado pediente de ingreso'
    }, {
      key: 'CONTRATADO',
      name: 'Contratado'
    }, {
      key: 'COMPLETADO',
      name: 'Completado'
    }, {
      key: 'ENVIADO_REQUERIMIENTO',
      name: 'Enviado a otro requerimiento'
    }],
    APPLICATION_STATUS_PRE_SCREENING: 'PRE_SCREENING',
    APPLICATION_STATUS_RECHAZADO: 'RECHAZADO',
    APPLICATION_STATUS_PRUEBAS: 'PRUEBAS',
    APPLICATION_STATUS_RECHAZADO_EN_PRUEBAS: 'RECHAZADO_EN_PRUEBAS',
    APPLICATION_STATUS_EN_ENTREVISTA: 'EN_ENTREVISTA',
    APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA: 'RECHAZADO_EN_ENTREVISTA',
    APPLICATION_STATUS_EN_TERNA: 'EN_TERNA',
    APPLICATION_STATUS_CONFIRMADO_PENDIENTE: 'CONFIRMADO_PENDIENTE_DE_INGRESO',
    APPLICATION_STATUS_CONTRATADO: 'CONTRATADO',
    // Una aplicaion adquiere este estado cuando se acepta en ingreso y el servicio es de reclutamiento
    // y seleccion
    APPLICATION_STATUS_COMPLETADO: 'COMPLETADO',
    // Una aplicación adquiere este estado cuando es "replicada" hacia un nuevo requerimiento
    APPLICATION_STATUS_ENVIADO_REQUERIMIENTO: 'ENVIADO_REQUERIMIENTO',
    REQUIREMENT_APPLICATION_STATUS: [{
      key: 'PRE_SCREENING',
      name: 'Pre screening'
    }, {
      key: 'RECHAZADO',
      name: 'Rechazado'
    }, {
      key: 'BLACKLIST',
      name: 'Blacklist'
    }],
    PERIODICITY: [{
      key: 'UNICA',
      name: 'Única'
    }, {
      key: 'MENSUAL',
      name: 'Mensual'
    }, {
      key: 'SIN_FACTURACION',
      name: 'Sin facturación'
    }],
    PERIODICITY_UNICA: 'UNICA',
    PERIODICITY_MENSUAL: 'MENSUAL',
    PERIODICITY_SIN_FACTURACION: 'SIN_FACTURACION',
    BILLING_STATUS: [{
      key: 'PENDIENTE_DE_EMITIR',
      name: 'Pendiente de emitir'
    }, {
      key: 'AUTORIZADA',
      name: 'Autorizada'
    }, {
      key: 'EN FACTURACION',
      name: 'En facturación'
    }, {
      key: 'EMITIDA',
      name: 'Emitida'
    }, {
      key: 'ANULADA',
      name: 'Anulada'
    }],
    BILLING_STATUS_PENDIENTE_DE_EMITIR: 'PENDIENTE_DE_EMITIR',
    BILLING_STATUS_AUTORIZADA: 'AUTORIZADA',
    BILLING_STATUS_EN_FACTURACION: 'EN FACTURACION',
    BILLING_STATUS_EMITIDA: 'EMITIDA',
    BILLING_STATUS_ANULADA: 'ANULADA',
    INTERVIEW_STATUS: [{
      key: 'PENDIENTE',
      name: 'Pendiente'
    }, {
      key: 'REALIZADA',
      name: 'Realizada'
    }],
    INTERVIEW_STATUS_PENDIENTE: 'PENDIENTE',
    INTERVIEW_TYPE: [{
      key: 'PRESENCIAL',
      name: 'Presencial'
    }, {
      key: 'TELEFONICA/VIRTUAL',
      name: 'Telefonica/Virtual'
    }],
    INTERVIEW_TYPE_PRESENCIAL: 'PRESENCIAL',
    REFERENCE_TYPE: [{
      key: 'CLIENTE',
      name: 'Cliente'
    }, {
      key: 'PROVEEDOR',
      name: 'Proveedor'
    }],
    REFERENCE_STATUS: [{
      key: 'APROBADA',
      name: 'Aprobada'
    }, {
      key: 'RECHAZADA',
      name: 'Rechazada'
    }],
    REPORTS_JOB_TYPES: [{
      key: 'PLAZAS_SOLICITADAS',
      name: 'Plazas solicitadas'
    }, {
      key: 'PLAZAS_SOLICITADAS_REGION',
      name: 'Plazas solicitadas por región'
    }, {
      key: 'PLAZAS_CONTRATADAS',
      name: 'Plazas contratadas'
    }, {
      key: 'PLAZAS_CONTRATADAS_FUENTE_RECLUTAMIENTO',
      name: 'Plazas contratadas por fuente de reclutamiento'
    }],
    REPORTS_JOB_TYPES_SOLICITADAS: 'PLAZAS_SOLICITADAS',
    REPORTS_JOB_TYPES_SOLICITADAS_REGION: 'PLAZAS_SOLICITADAS_REGION',
    REPORTS_JOB_TYPES_CONTRATADAS: 'PLAZAS_CONTRATADAS',
    REPORTS_JOB_TYPES_CONTRATADAS_FUENTE: 'PLAZAS_CONTRATADAS_FUENTE_RECLUTAMIENTO',
    REPORTS_CANDIDATES_TYPES: [{
      key: 'CANDIDATO_ESPECIFICO',
      name: 'Candidato especifico'
    }, {
      key: 'CANDIDATO_LISTA_NEGRA',
      name: 'Candidato en lista negra'
    }, {
      key: 'CANDIDATOS_POR_PROYECTO',
      name: 'Candidatos por proyecto'
    }, {
      key: 'CANTIDAD_CANDIDATOS_POR_PROYECTO',
      name: 'Cantidad de candidatos por proyecto'
    }],
    REPORTS_SPECIFIC_CANDIDATE: 'CANDIDATO_ESPECIFICO',
    REPORTS_BLACK_LIST_CANDIDATE: 'CANDIDATO_LISTA_NEGRA',
    REPORTS_CANDIDATES_PER_PROJECT: 'CANDIDATOS_POR_PROYECTO',
    REPORTS_NUMBER_CANDIDATES_PER_PROJECT: 'CANTIDAD_CANDIDATOS_POR_PROYECTO',
    REQUIREMENT_SHIFT: [{
      key: 'DIURNA',
      name: 'Diurna'
    }, {
      key: 'MIXTA',
      name: 'Mixta'
    }, {
      key: 'NOCTURNA',
      name: 'Nocturna'
    }, {
      key: 'POR_HORAS',
      name: 'Por horas'
    }],
    REQUIREMENT_SHIFT_ELTAIER: [{
      key: 'DIURNA',
      name: 'Diurna'
    }, {
      key: 'POR_HORAS',
      name: 'Por horas'
    }, {
      key: 'POR_PROYECTO',
      name: 'Por proyecto'
    }],
    WEEK_DAYS: [{
      key: 'LUNES_VIERNES',
      name: 'Lunes - Viernes'
    }, {
      key: 'SABADO',
      name: 'Sábado'
    }, {
      key: 'DOMINGO',
      name: 'Domingo'
    }],
    GENDER: [{
      key: 'MASCULINO',
      name: 'Masculino'
    }, {
      key: 'FEMENINO',
      name: 'Femenino'
    }, {
      key: 'INDIFERENTE',
      name: 'Indiferente'
    }],
    MARITIAL_STATUS: [{
      key: 'CASADO',
      name: 'Casado'
    }, {
      key: 'SOLTERO',
      name: 'Soltero'
    }, {
      key: 'INDIFERENTE',
      name: 'Indiferente'
    }],
    LICENCE_TYPE: [{
      key: 'TIPO_A',
      name: 'Tipo A'
    }, {
      key: 'TIPO_B',
      name: 'Tipo B'
    }, {
      key: 'TIPO_C',
      name: 'Tipo C'
    }, {
      key: 'TIPO_E',
      name: 'Tipo E'
    }, {
      key: 'TIPO_M',
      name: 'Tipo M'
    }],
    REPORT_APLICACIONES: [{
      key: 'REPORTE_GENERAL',
      name: 'General'
    }, {
      key: 'REPORTE_RESUMEN',
      name: 'Resumen'
    }],
    REPORT_APLICACIONES_GENERAL: 'REPORTE_GENERAL',
    REPORT_APLICACIONES_RESUMEN: 'REPORTE_RESUMEN',
    REPORT_REQUERIMIENTO: [{
      key: 'REPORTE_GENERAL',
      name: 'General'
    }, {
      key: 'REPORTE_CONTRATADOS',
      name: 'Contratados'
    }],
    REPORT_REQUERIMIENTO_GENERAL: 'REPORTE_GENERAL',
    REPORT_REQUERIMIENTO_CONTRATADOS: 'REPORTE_CONTRATADOS',
    CLIENT_CONTACT_TYPE: [{
      key: 'FACTURACION',
      name: 'Facturación'
    }, {
      key: 'SOLICITUD',
      name: 'Solicitud'
    }],
    MONTHS_BY_INDEX: [{
      key: 0,
      name: 'Enero'
    }, {
      key: 1,
      name: 'Febrero'
    }, {
      key: 2,
      name: 'Marzo'
    }, {
      key: 3,
      name: 'Abril'
    }, {
      key: 4,
      name: 'Mayo'
    }, {
      key: 5,
      name: 'Junio'
    }, {
      key: 6,
      name: 'Julio'
    }, {
      key: 7,
      name: 'Agosto'
    }, {
      key: 8,
      name: 'Septiembre'
    }, {
      key: 9,
      name: 'Octubre'
    }, {
      key: 10,
      name: 'Noviembre'
    }, {
      key: 11,
      name: 'Diciembre'
    }],
    NOTIFICATION_CONFIG_EVENTS: [{
      key: '',
      name: 'Seleccionar'
    }, {
      key: 'ENTITY_CREATED',
      name: 'Entidad creada'
    }, {
      key: 'ENTITY_PROPERTY_UPDATED',
      name: 'Propiedad actualizada'
    }, {
      key: 'CRON',
      name: 'Tarea recursiva'
    }],
    ACADEMIC_FORMATION: [{
      key: 'SECUNDAROA',
      name: 'Secundaria'
    }, {
      key: 'TECNICA_BASICA',
      name: 'Técnica básica (1 ó 2 años)'
    }, {
      key: 'TECNICA_SUPERIOR',
      name: 'Técnica superior (3 ó 4 años)'
    }, {
      key: 'UNIVERSITARIO',
      name: 'Universitario'
    }],
    ACADEMIC_DEGREE: [{
      key: 'BACHILLER',
      name: 'Bachiller'
    }, {
      key: 'TITULO',
      name: 'Título/Licenciatura'
    }],
    MASTERS_ESTATUS: [{
      key: 'PROGRESO',
      name: 'En progreso'
    }, {
      key: 'GRADUADO',
      name: 'Graduado'
    }],
    OTROS_CURSOS: [{
      key: 'CURSOS',
      name: 'Cursos'
    }, {
      key: 'DIPLOMADOS',
      name: 'Diplomados'
    }],
    WORKING_HOURS: [{
      key: 'SIETE_A_CINCO',
      name: '7:00-5:00'
    }, {
      key: 'OCHO_A_SEIS',
      name: '8:00-6:00'
    }, {
      key: 'NUEVA_A_SIETE',
      name: '9:00-7:00'
    }],
    MASTERY_LEVEL: [{
      key: 'BASICO',
      name: 'Básico'
    }, {
      key: 'MEDIO',
      name: 'Medio'
    }, {
      key: 'AVANZADO',
      name: 'avanzado'
    }],
    COMPETENCE_TYPE: [{
      key: 'TECNICA',
      name: 'Técnica'
    }, {
      key: 'LIDERAZGO',
      name: 'Liderazgo'
    }]
  };
  _exports.default = _default;
});