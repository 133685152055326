define("ods-app/templates/usuario/record/generales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ibEcvPdF",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"codigo\",\"Código:\"],[[\"placeholder\"],[\"Código\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"nombres\",\"Nombres:\"],[[\"placeholder\"],[\"Nombres\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"apellidos\",\"Apellidos:\"],[[\"placeholder\"],[\"Apellidos\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"email\",\"Correo:\"],[[\"placeholder\"],[\"Correo\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"dpi\",\"DPI:\"],[[\"placeholder\"],[\"DPI\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"nit\",\"NIT:\"],[[\"placeholder\"],[\"NIT\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"departamento\",\"Departamento:\"],[[\"placeholder\"],[\"Departamento\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"celular\",\"Celular:\"],[[\"placeholder\"],[\"Celular\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"telefono\",\"Teléfono:\"],[[\"placeholder\"],[\"Teléfono\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"direccion\",\"Dirección:\"],[[\"placeholder\"],[\"Dirección\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"enabled\",\"Activo:\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/usuario/record/generales.hbs"
    }
  });

  _exports.default = _default;
});