define("ods-app/routes/requerimiento/record/perfil", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute", "ods-app/mixins/permission-validator"], function (_exports, _isTabSubroute, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, _permissionValidator.default, {
    routePermission: 'VIEW_RQ_TAB_PROFILE',
    noPermissionTransitionRoute: 'requerimiento'
  });

  _exports.default = _default;
});