define("ods-app/models/requerimiento", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default; // const { all } = RSVP;

  var _default = Model.extend({
    datesCalculator: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    codigo: attr('string'),
    proyecto: belongsTo('proyecto'),
    fechaSolicitud: attr('custom-date', {
      defaultValue() {
        return new Date();
      }

    }),
    updatedAt: attr('custom-date'),
    estatus: attr('string'),
    reclutador: belongsTo('usuario'),
    plaza: attr('string'),
    cantidadPlazas: attr('number', {
      defaultValue: 1
    }),
    tipoPlaza: attr('string'),
    nombrePlaza: attr('string'),
    pais: belongsTo('pais'),
    departamento: belongsTo('departamento'),
    municipio: belongsTo('municipio'),
    ubicacion: attr('string'),
    nombreJefeInmediato: attr('string'),
    sustituye: attr('string'),
    supervisorOperativo: attr('string'),
    jornada: attr('string'),
    // dias: attr('string'),
    diasHorario: attr('string'),
    montoHonorario: attr('number', {
      defaultValue: 0
    }),
    salarioBase: attr('number', {
      defaultValue: 0
    }),
    comisiones: attr('number', {
      defaultValue: 0
    }),
    comisionesEspecificas: attr('number', {
      defaultValue: 0
    }),
    bonificacionIncenAdmin: attr('number', {
      defaultValue: 0
    }),
    bonificacionIncen: attr('number', {
      defaultValue: 0
    }),
    devolucionISR: attr('number', {
      defaultValue: 0
    }),
    bonoTransporte: attr('number', {
      defaultValue: 0
    }),
    bonificacionDecreto: attr('number', {
      defaultValue: 0
    }),
    gasolina: attr('number', {
      defaultValue: 0
    }),
    fechaSeguimientoProgramada: attr('custom-date'),
    fechaSeguimiento: attr('custom-date'),
    responsable: belongsTo('usuario'),
    horaContacto: attr('custom-date'),
    aplicaciones: hasMany('aplicacion'
    /*, { inverse: 'requerimiento' }*/
    ),
    ternas: hasMany('terna'),
    comentarios: attr('string'),
    observacionesRecursos: attr('string'),
    observacionesComercial: attr('string'),
    factorhPuestoId: attr('number'),
    factorhPuestoStr: attr('string'),
    correo: attr('string'),
    telefono: attr('number'),
    responsableSolicitud: attr('string'),
    responsablePuesto: attr('string'),
    responsableCorreo: attr('string'),
    responsableTelefono: attr('string'),
    escolaridad: attr('string'),
    edad: attr('string'),
    religion: attr('string'),
    idiomas: attr(),
    genero: attr('string'),
    estadoCivil: attr('string'),
    programasComputacion: attr(),
    areasExperiencia: attr(),
    anosExperiencia: attr('number', {
      defaultValue: 0
    }),
    presentacion: attr('number'),
    vehiculo: attr('boolean'),
    infoVehiculo: attr('string'),
    tipoLicencia: attr('string'),
    funcionesBasicas: attr('string'),
    competencias: attr('string'),
    responsabilidades: attr('string'),
    personalBajoCargo: attr('number', {
      defaultValue: 0
    }),

    /**
     * Aquí se almacena la fecha en la que un requerimiento es marcado como confirmado
     * pendiente de ingreso, que es cuando se detiene el contador de días del proceso
     * de reclutamiento y selección
     */
    fechaFinalizacion: attr('custom-date'),
    fechaInicioLabores: attr('custom-date'),

    /**
     * @deprecated
     */
    diasProceso: attr('string', {
      defaultValue: 0
    }),
    comentariosIngreso: attr('string'),
    entrevistas: hasMany('entrevista'),
    llamadas: hasMany('llamada'),
    esReprocesoDe: belongsTo('requerimiento', {
      inverse: null
    }),
    estaDeshabilitado: attr('boolean', {
      defaultValue: false
    }),
    esEditable: attr('boolean', {
      defaultValue: true
    }),
    esUrgente: attr('boolean', {
      defaultValue: false
    }),
    createdAt: attr('custom-date'),
    planillaCargos: hasMany('requerimiento-planilla-cargo'),
    razonCancelacion: belongsTo('razon-cancelacion'),
    observacionesCancelacion: attr('string'),
    usuarioCancelacion: belongsTo('usuario'),
    fechaCancelacion: attr('custom-date'),
    noEsEditable: Ember.computed.not('esEditable'),
    noEsEditablePorUsuario: Ember.computed('noEsEditable', 'estaDeshabilitado', 'estatus', function () {
      if (this.get('currentUser').hasPermission('COMPLETE_REQUIREMENT_DATA') && (this.estatus === this.constants.REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE || this.estatus === this.constants.REQUIREMENT_STATUS_VACANTE)) {
        return false;
      }

      return this.noEsEditable || this.estaDeshabilitado;
    }),
    estaArchivado: Ember.computed('estatus', function () {
      return [this.constants.REQUIREMENT_STATUS_CONGELADO, this.constants.REQUIREMENT_STATUS_CANCELADO].includes(this.estatus);
    }),
    createdAtFormatted: Ember.computed('createdAt', function () {
      const momentDate = (0, _moment.default)(this.createdAt);

      if (Ember.isNone(this.createdAt) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    fechaSolicitudFormatted: Ember.computed('fechaSolicitud', function () {
      const momentDate = (0, _moment.default)(this.fechaSolicitud);

      if (Ember.isNone(this.fechaSolicitud) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    fechaSeguimientoProgramadaFormatted: Ember.computed('fechaSeguimientoProgramada', function () {
      const momentDate = (0, _moment.default)(this.fechaSeguimientoProgramada);

      if (Ember.isNone(this.fechaSeguimientoProgramada) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    fechaCancelacionFormatted: Ember.computed('fechaCancelacion', function () {
      const momentDate = (0, _moment.default)(this.fechaCancelacion);

      if (Ember.isNone(this.fechaCancelacion) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY hh:mm a');
    }),
    statusToString: Ember.computed('estatus', function () {
      const status = this.constants.REQUIREMENT_STATUS.findBy('key', this.estatus);
      return status ? status.name : this.status;
    }),
    updatedAtFormatted: Ember.computed('updatedAt', function () {
      let date = (0, _moment.default)(this.get('updatedAt') || '');
      return date.isValid() ? date.format('DD/MM/YYYY hh:mm a') : '';
    }),
    ternaActiva: Ember.computed('ternas', function () {
      const ternaActiva = this.ternas.findBy('activo');
      return ternaActiva;
    }),
    nombreReq: Ember.computed('codigo', 'nombrePlaza', function () {
      return `${this.codigo} - ${this.nombrePlaza}`;
    }),
    esUrgenteFormatted: Ember.computed('esUrgente', function () {
      return this.esUrgente ? 'Sí' : 'No';
    }),
    cantidadDiasProceso: Ember.computed('fechaFinalizacion', 'fechaSolicitud', function () {
      if (!this.fechaSolicitud) {
        return;
      }

      let comparacion = new Date();

      if (this.fechaFinalizacion) {
        comparacion = this.fechaFinalizacion;
      }

      let diferencia = this.get('datesCalculator').getCantidadDiasHabiles(this.fechaSolicitud, comparacion);
      return diferencia;
    }) // save(options) {
    //   // TODO: Corregir la localización de aplicaciones y ternas modificadas corrigiendo
    //   // los errores al usar el changeTracker
    //   // let aplicaciones = this.get('aplicaciones').filter(a => a.get('isDirty'));
    //   let aplicaciones = this.get('aplicaciones');
    //   return this._super(options)
    //     .then(() => all(aplicaciones.map((e) => {
    //       return e.save();
    //     })))
    //     .then(() => {
    //       const ternas = this.get('ternas').filter(t => t.get('isDirty'));
    //       return RSVP.all(ternas.map(t => t.save()));
    //     })
    //     .then(() => this);
    // }

  });

  _exports.default = _default;
});