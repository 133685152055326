define("ods-app/controllers/requerimiento/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/requerimiento"], function (_exports, _abstractModuleIndex, _requerimiento) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'requerimiento',
    modelClass: _requerimiento.default,
    editRoute: 'requerimiento.record',
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'codigo'
      }]);
    }),
    modelFilters: Ember.computed(function () {
      return [{
        selectedFilter: {
          filterName: 'estatus'
        },
        value: [this.constants.REQUIREMENT_STATUS_BORRADOR, this.constants.REQUIREMENT_STATUS_VACANTE, this.constants.REQUIREMENT_STATUS_CONGELADO]
      }];
    }),
    modelFiltersPendientesIngreso: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'estatus'
        },
        value: this.constants.REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE
      }];
    }),
    filterSituationsOptions: Ember.computed(function () {
      return [{
        key: this.constants.REQUIREMENT_SITUATION_EN_TIEMPO,
        name: 'EN TIEMPO'
      }, {
        key: this.constants.REQUIREMENT_SITUATION_FUERA_DE_TIEMPO,
        name: 'FUERA DE TIEMPO'
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: true,
        width: '60px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Fecha solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: true,
        width: '90px',
        sortingPropertyName: 'fechaSolicitud',
        sorted: true,
        ascending: false
      }, {
        label: 'Situación',
        valuePath: 'ternaActiva.situacion',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'custom.situacion',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: this.filterSituationsOptions
      }, {
        label: 'Es urgente',
        valuePath: 'esUrgenteFormatted',
        sortable: false,
        width: '50px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'estatus',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: this.constants.REQUIREMENT_STATUS
      }, {
        label: 'Reclutador',
        valuePath: 'reclutador.username',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'reclutador'
      }, {
        label: 'Cliente',
        valuePath: 'proyecto.cliente.nombre',
        sortable: false,
        width: '200px',
        filtrable: true,
        filterName: 'proyecto.cliente.nombre'
      }, {
        label: 'Departamento',
        valuePath: 'departamento.nombre',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'proyecto.cliente.nombre'
      }, {
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: true,
        width: '140px',
        filtrable: true,
        filterName: 'nombrePlaza'
      }, {
        label: 'Fecha de seguimiento',
        valuePath: 'fechaSeguimientoProgramadaFormatted',
        sortable: false,
        width: '90px'
      }, {
        label: 'Fecha vencimiento',
        valuePath: 'ternaActiva.fechaVencimientoEnvioFormatted',
        sortable: false,
        width: '90px'
      }, {
        label: 'Tiempo disponible',
        valuePath: 'ternaActiva.tiempoDisponible',
        sortable: false,
        width: '80px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),
    columnsPendienteIngreso: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: true,
        width: '60px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Fecha solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: true,
        width: '90px',
        sortingPropertyName: 'fechaSolicitud',
        sorted: true,
        ascending: false
      }, {
        label: 'Situación',
        valuePath: 'ternaActiva.situacion',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'custom.situacion',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: this.filterSituationsOptions
      }, {
        label: 'Es urgente',
        valuePath: 'esUrgenteFormatted',
        sortable: false,
        width: '50px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'estatus',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: this.constants.REQUIREMENT_STATUS
      }, {
        label: 'Reclutador',
        valuePath: 'reclutador.username',
        sortable: false,
        width: '100px'
      }, {
        label: 'Departamento',
        valuePath: 'departamento.nombre',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'departamento.nombre'
      }, {
        label: 'Cliente',
        valuePath: 'proyecto.cliente.nombre',
        sortable: false,
        width: '200px',
        filtrable: true,
        filterName: 'proyecto.cliente.nombre'
      }, {
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: true,
        width: '140px',
        filtrable: true,
        filterName: 'nombrePlaza'
      }, {
        label: 'Fecha de seguimiento',
        valuePath: 'fechaSeguimientoProgramadaFormatted',
        sortable: false,
        width: '90px'
      }, {
        label: 'Fecha ingreso',
        valuePath: 'fechaIngreso',
        sortable: false,
        width: '90px'
      }, {
        label: 'Tiempo del proceso',
        valuePath: 'cantidadDiasProceso',
        sortable: false,
        width: '80px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    })
  });

  _exports.default = _default;
});