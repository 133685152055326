define("ods-app/controllers/usuario/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/usuario"], function (_exports, _abstractModuleIndex, _usuario) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'usuario',
    modelClass: _usuario.default,
    editRoute: 'usuario.record',
    defaultFilters: Ember.computed(function () {
      return [{
        selectedFilter: {
          filterName: 'isSuperAdmin'
        },
        value: 0
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        filtrable: true,
        filterName: 'codigo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Nombres',
        valuePath: 'nombres',
        filtrable: true,
        filterName: 'nombres',
        sortable: false,
        width: '130px'
      }, {
        label: 'Apellidos',
        valuePath: 'apellidos',
        filtrable: true,
        filterName: 'apellidos',
        sortable: false,
        width: '130px'
      }, {
        label: 'Usuario',
        valuePath: 'username',
        filtrable: true,
        filterName: 'username',
        sortable: false,
        width: '100px'
      }, {
        label: 'Rol',
        valuePath: 'role.nombre',
        sortable: false,
        width: '150px'
      }, {
        label: 'Email',
        valuePath: 'email',
        sortable: false,
        width: '200px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    })
  });

  _exports.default = _default;
});