define("ods-app/routes/requerimiento/record/ingreso/asociar-proyecto", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    model(params, transition) {
      let requerimiento = this.modelFor('requerimiento.record').record;
      let clienteId = requerimiento.get('proyecto.cliente.id');
      let query = {
        proyectos: this.store.query('proyecto', {
          filter: {
            'servicioAdquirido.tipoServicio': this.constants.SERVICE_TYPES_PAYROLL_ADMINISTRATION_FACTORH,
            'cliente.id': clienteId
          },
          include: 'servicioAdquirido,cliente'
        })
      };

      if (!Ember.isNone(transition.queryParams.aplicacionId)) {
        let queryAplicacion = {
          aplicacionPlaceHolder: this.store.query('aplicacion', {
            filter: {
              id: transition.queryParams.aplicacionId
            }
          })
        };
        query = Object.assign(query, queryAplicacion);
      }

      return Ember.RSVP.hash(query);
    },

    setupController(controller, model) {
      this._super(controller, model);

      const requerimiento = this.modelFor('requerimiento.record').record;
      controller.set('requerimiento', requerimiento);

      if (!Ember.isNone(model.aplicacionPlaceHolder)) {
        let aplicacion = model.aplicacionPlaceHolder.get('firstObject');
        controller.set('aplicacion', aplicacion);
      }
    }

  });

  _exports.default = _default;
});